import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import RequestModal from "./Modal/RequestModal"
import TestRequestTable from "./TestRequestTable"

import {
    Check,
    ConfirmModal2,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    convertDateFormat,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"

import {
    resetSearchQuery
} from "store/laboratory/tests/actions"

import {
    cancelTestRequest,
    createNewRequest,
    deleteRequests,
    emptyTestRequestDetail,
    getBillingTypes,
    getRequests,
    getTestRequestDetail,
    getTestRequestSample,
    updateTestRequest,
} from "store/laboratory/testRequest/actions"

import { ModuleIds, PartyProfile, TestRequest_State, TestRequest_Test_Type, permissionType } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { exportRequestList, getOrganizationById } from "helpers/app-backend"
import moment from "moment"


const RESOURCE = ModuleIds.TestRequest
let modelGlobal = {
    search: "",
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD"),
    physicianId: "",
    departmentId: "",
    state: "",
}
const TestRequest = ({
    history,
    paging,
    onGetRequests,
    onDeleteRequests,
    onResetTestSearchQuery,
    testRequests,
    testRequest,
    loadingRequests,
    updateRequestsTime,
    onGetTestRequestDetail,
    onCancelTestRequest,
    onCreateNewRequest,
    onUpdateTestRequest,
    onGetBillingTypes,
    onEmptyTestRequestDetail,
    testRequestSamples,
    testRequestTests,
    onGetTestRequestSample,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [checkDisabledCancel, setCheckDisabledCancel] = useState(false)
    const resource = RESOURCE ? true : false
    const formEl = useRef(null)
    const afterUpdate = () => {
        toggle()
        fetchRequests()
    }

    const toggle = () => {
        if (!modal) onGetBillingTypes();
        setModal(prev => !prev)
    }

    const addRequestClicks = () => {
        onEmptyTestRequestDetail();
        setIsEdit(false)
        setIsClone(false)
        toggle();
    }

    /**
    * Handling submit Request on form
    */
    const userInSesstion = JSON.parse(localStorage.getItem("userInSesstion"));
    const handleValidRequestSubmit = async (values) => {
        values.patientGroupType = 'S'

        if (values.individualValues) {
            delete values.individualValues.patientId
            values.individualValues.insuranceNumber = values.insuranceNumber;
            values.individualValues.inPatient = values.inPatient;
            values.individualValues.emergency = values.emergency;
            if (!values.individualValues?.ProfileId) {
                values.individualValues.ProfileId = PartyProfile.DEFAULT_PATIENT;
            }
        }

        values.patientId = `${values.individualValues?.PatientId || ''}`;
        values.resultTime = isNaN(values.resultTime) ? null : (values.resultTime == "" ? null : values.resultTime)
        let tests = [];
        let profiles = [];
        let sidParam = {
            FullDate: moment(new Date(values?.requestDate)).format("DDMMYY"),
            Year: convertDateFormat(values?.requestDate, "YYYY")
        }
        //let samples = [];
        // onGetTestRequestSample()
        testRequestTests.forEach(item => {
            //push test đơn
            if (item.type === TestRequest_Test_Type.TEST) {
                tests.push({
                    testCode: item.code,
                    testCategory: item.category,
                    sampleType: item.sampleType,
                    profileCode: item.parentCode,
                    sID: item?.sid || 0
                });
            } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
                //push profile group vào list
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });

                if (item.children) {
                    //push tất cả child trực tiếp là test vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.TEST).map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0
                        });
                    })

                    //push tất cả child trực tiếp là profile vào list
                    item.children.filter(x => x.type === TestRequest_Test_Type.PROFILE).map(profile => {
                        profiles.push({
                            profileCode: profile.code,
                            profileCategory: profile.category
                        });
                        if (profile.children) {
                            //push tất cả test cháu vào list
                            profile.children.map(test => {
                                tests.push({
                                    testCode: test.code,
                                    testCategory: test.category,
                                    sampleType: test.sampleType,
                                    profileCode: test.parentCode,
                                    sID: test?.sid || 0
                                });
                            })
                        }
                    })
                }
            } else if (item.type === TestRequest_Test_Type.PROFILE) {
                //push profile bên ngoài
                profiles.push({
                    profileCode: item.code,
                    profileCategory: item.category
                });
                if (item.children) {
                    item.children.map(test => {
                        tests.push({
                            testCode: test.code,
                            testCategory: test.category,
                            sampleType: test.sampleType,
                            profileCode: test.parentCode,
                            sID: test?.sid || 0
                        });
                    })
                }
            }
        })
        values.GroupCode = ''
        if (values.ParentId) {
            const data = await getOrganizationById(values.ParentId)
            values.GroupCode = data.OrganizationCode
        }

        values.tests = tests;
        values.profiles = profiles;
        values.sidParam = sidParam
        if (userInSesstion?.area) {
            values.area = userInSesstion?.area
        }
        //values.samples = samples;
        if (isEdit) {
            onUpdateTestRequest({ request: values, callback: afterUpdate })
        } else {
            onCreateNewRequest({ request: values, history: history, callback: afterUpdate })
        }
    }

    const onCloneHandler = (data) => {
        const id = data || row?.id;
        if (id) {
            onGetTestRequestDetail(id);
            setIsEdit(false);
            setIsClone(true);
            toggle();
        } else {
            setWarningModal(true);
        }

    }

    const onEditHandler = (e, requestId) => {
        const id = requestId || row?.id;
        if (id) {
            history.push(`/TestRequest/${row.id}/edit?tab=1`)
        } else {
            setWarningModal(true);
        }
    }

    const onCancelToggleHandler = (e, request) => {
        if (row?.id) {
            setRow(request)
            setConfirmModal(true)
            setWarningModal(false)
        }
        else {
            setWarningModal(true)
        }

    }

    const onCancelToggleTable = (e, request) => {
        setRows([request])
        setRow(request)
        setConfirmModal(true)
        setWarningModal(false)
    }

    const onCancelToggle = () => {
        setConfirmModal(false);
    }

    const onCancelTestRequestHandler = () => {
        if (row?.id) {
            const requestToCancel = rows?.filter(row => row?.state === TestRequest_State.Draft || row?.state === TestRequest_State.Submitted)
            onCancelTestRequest({
                request: requestToCancel, callback: () => {
                    resetState()
                    onRefreshAfterAction()
                }
            })
            setConfirmModal(false)
            setWarningModal(false)
        } else {
            setWarningModal(true)
            setConfirmModal(false)
        }
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onDeleteToggleHandler = (e, request) => {
        onDeleteToggle({
            rows,
            row: request || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteRequests({
            requests: rowsState, callback: () => {
                resetState()
                onRefreshAfterAction()
            }
        })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteRequests({
            requests: rowState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id != rowDelete.id))
                onRefreshAfterAction()
            }
        })
        setRowDelete({});
        setRow({});
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        if (rowsState.every(_row => _row.state !== TestRequest_State.Draft && _row.state !== TestRequest_State.Submitted)) {
            setCheckDisabledCancel(true)
        } else {
            setCheckDisabledCancel(false)
        }
        if (rowsState?.length < 1 || !currentRow) {
            setCheckDisabledCancel(false)
        }
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.every(_row => _row.state !== TestRequest_State.Draft && _row.state !== TestRequest_State.Submitted)) {
            setCheckDisabledCancel(true)
        }
        if (rows.length < 1) {
            setRow({})
            setCheckDisabledCancel(false)
        }
        else setRow(rows[rows.length - 1])
    }

    const onGetRequestList = (payload) => {
        insertUrlParam({ page: payload.page, size: payload.size, sort: payload.sort, start: payload.start, end: payload.end })
        onGetRequests({...modelGlobal, ...payload })
    }
    const fetchRequests = () => {
        onGetRequestList({ page: 1, sort: "createdDate:desc" })
    }

    const fetchRequestsCurrentPage = () => {
        onGetRequestList({})
    }

    const onRefreshHandler = () => {
        resetState();
        fetchRequests();
    }

    const onRefreshAfterAction = () => {
        resetState()
        fetchRequestsCurrentPage()
    }

    const onSearch = searchText => {
        onGetRequestList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetRequestList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetRequestList({ page })
    }

    const onSubmitFilter = values => {
        onGetRequestList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetRequestList({ page: 1, sort: sortString })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetRequestList({ ...params })
        } else {
            fetchRequests()
        }
    }, [])

    const onExportRequestListHandler = async () => {
        let payload = { page: 1, sort: "createdDate:desc" }
        const fileData = await exportRequestList({ ...payload, ...modelGlobal, page: payload.page, size: payload.size, })
        const blob = new Blob([fileData])
        saveAs(blob, "ExportRequestList.xlsx")
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <TestRequestTable
                        testRequests={testRequests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onCancel={onCancelToggleTable}
                        onClone={onCloneHandler}
                        loading={loadingRequests}
                        updatedTime={updateRequestsTime}
                        onChangeModel={val => {
                            modelGlobal = val
                        }}
                        onExportRequestList={onExportRequestListHandler}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addRequestClicks} outline>
                            {t("testRequestPage:Add Request")}
                        </CustomButton>
                        <CustomButton color="primary" onClick={() => { onCancelToggleHandler(null, row) }} outline disabled={checkDisabledCancel}>
                            {t("common:Cancel")}
                        </CustomButton>
                    </Check>
                )}
                onEdit={onEditHandler}
                onCancel={() => { onCancelToggleHandler(null, row) }}
                onClone={onCloneHandler}
                title={t("Test Request")}
                subtitle={t("Test Request List")}
                external
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={`${t("common:Cancel")} ${t("Test Request")}`}
                message={t("message:CancelConfirm")}
                onToggle={onCancelToggle}
                onConfirm={onCancelTestRequestHandler}
            />
            <RequestModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                isClone={isClone}
                onValidSubmit={handleValidRequestSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? testRequest : {}) : testRequest}
            />
        </React.Fragment>
    )
}

TestRequest.propTypes = {
    paging: PropTypes.object,
    onGetRequests: PropTypes.func,
    onDeleteRequests: PropTypes.func,
    onResetTestSearchQuery: PropTypes.func,
    t: PropTypes.any,
    onCreateNewRequest: PropTypes.func,
    onGetBillingTypes: PropTypes.func,
    loadingRequests: PropTypes.bool,
    updateRequestsTime: PropTypes.object,
    testRequestSamples: PropTypes.array,
    testRequestTests: PropTypes.array,
    onGetTestRequestDetail: PropTypes.func,
    onCancelTestRequest: PropTypes.func
}

const mapStateToProps = ({ testRequest }) => ({
    testRequests: testRequest.testRequests,
    paging: testRequest.paging,
    loadingRequests: testRequest.loadingRequests,
    updateRequestsTime: testRequest.updateRequestsTime,
    testRequest: testRequest.testRequest,
    testRequestSamples: testRequest.testRequest.samples || [],
    testRequestTests: testRequest.testRequest.tests || [],
})

const mapDispatchToProps = dispatch => ({
    onGetRequests: payload => dispatch(getRequests(payload)),
    onDeleteRequests: ({ requests, callback }) => dispatch(deleteRequests({ requests, callback })),

    onCancelTestRequest: ({ request, callback }) => dispatch(cancelTestRequest({ request, callback })),

    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),

    onCreateNewRequest: ({ request, history, callback }) =>
        dispatch(createNewRequest({ request, history, callback })),

    onUpdateTestRequest: ({ request, callback }) =>
        dispatch(updateTestRequest({ request, callback })),

    onGetTestRequestDetail: requestId => dispatch(getTestRequestDetail(requestId)),

    onGetBillingTypes: () =>
        dispatch(getBillingTypes()),

    onEmptyTestRequestDetail: () =>
        dispatch(emptyTestRequestDetail()),
    onGetTestRequestSample: payload => dispatch(getTestRequestSample(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message"])(TestRequest)))