import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal,
} from "components/Common"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import PrintConfigTable from "./PrintConfigTable"

import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, permissionType } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { addPrintConfig, deletePrintConfig, getPrintConfig, getPrintConfigDetail, updatePrintConfig } from "store/laboratory/printConfig/actions"
import PrintConfigModal from "./Modal/PrintConfigModal"

const RESOURCE = ModuleIds.PrintConfig

const PrintConfig = ({
    printConfig,
    paging,
    onGetPrintConfig,
    onAddNewPrintConfig,
    onUpdatePrintConfig,
    onDeletePrintConfig,
    onGetPrintConfigDetail,
    printConfigDetail,
    t
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const formEl = useRef(null)

    const toggle = () => {
        setModal(prev => !prev)
    }

    const addPrintConfigClicks = () => {
        setIsEdit(false)
        toggle()
    }

    const handleValidSubmit = (e, values, type) => {
        if (isEdit) {
            values.type = type
            onUpdatePrintConfig({ printConfig: values, callback: toggle })
        } else {
            values.type = type
            onAddNewPrintConfig({ printConfig: values, callback: onRefreshHandler })
        }
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetPrintConfigDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const onLabelChange = (item, numOfLabel) => {
        item.numOfLabel = numOfLabel
        onUpdatePrintConfig({ printConfig: item })
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeletePrintConfig({ printConfig: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeletePrintConfig({
            printConfig: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeletePrintConfigHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetPrintConfigList = (payload) => {
        // insertUrlParam(payload)
        onGetPrintConfig(payload)
    }

    const fetchPrintConfig = () => {
        onGetPrintConfigList({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState()
        fetchPrintConfig()
    }

    const onSearch = searchText => {
        onGetPrintConfigList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetPrintConfigList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetPrintConfigList({ page })
    }

    const onSubmitFilter = values => {
        onGetPrintConfigList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetPrintConfigList({ page: 1, sort: sortString })
    }

    const fetchPrintConfigWithPage = () => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetPrintConfigList(params)
        } else {
            fetchPrintConfig()
        }
    }

    useEffect(() => {
        fetchPrintConfigWithPage()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <PrintConfigTable
                        printConfigs={printConfig}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onLabelChange={onLabelChange}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addPrintConfigClicks} outline>
                            {t("Add Print Config")}
                        </CustomButton>
                        <CustomButton
                            color="primary"
                            onClick={e => {
                                onDeleteToggleHandler(e, rows.id)
                            }}
                            outline
                        >
                            {t("common:Delete")}
                        </CustomButton>
                    </Check>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Print Config")}
                subtitle={t("Print Config List")}
            />

            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Print Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeletePrintConfigHandler}
            />
            <PrintConfigModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={!isEdit ? row : printConfigDetail}
            />
        </React.Fragment>
    )
}

PrintConfig.propTypes = {
    printConfig: PropTypes.array,
    paging: PropTypes.array,
    t: PropTypes.any,
}

const mapStateToProps = ({ printConfig }) => {
    return ({
        printConfigDetail: printConfig.printConfigDetail,
        printConfig: printConfig.printConfig,
        paging: printConfig.paging,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetPrintConfig: payload => dispatch(getPrintConfig(payload)),
    onAddNewPrintConfig: (printConfig, callback) => dispatch(addPrintConfig(printConfig, callback)),
    onUpdatePrintConfig: (printConfig) => dispatch(updatePrintConfig(printConfig)),
    onDeletePrintConfig: printConfig => dispatch(deletePrintConfig(printConfig)),
    onGetPrintConfigDetail: payload => dispatch(getPrintConfigDetail(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["printConfigPage"])(PrintConfig)))
