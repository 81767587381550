import { Collapse, Label, TabContent, TabPane } from "reactstrap"

import {
    Check,
    CustomAutoComplete,
    CustomMultiSelectAsync,
    CustomNav,
    CustomNavLink,
    WarningModal
} from "components/Common"
import { convertDateFormat } from "helpers/utilities"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import TabSample from "./TabSample/TabSample"

import {
    CustomDatePicker
} from "components/Common"

import { AvForm } from "availity-reactstrap-validation"
import BadgeLabel from "components/Common/BadgeLabel"
import { DeliveryStateText, ModuleIds, SAMPLES_DELIVERY_TYPE, parameterCode, permissionType } from "constant"
import { connect } from "react-redux"
import { addDelivery, addDeliverySample, addDeliveryTest } from "store/actions"
import TabTest from "./TabTest/TabTest"

const RESOURCE = ModuleIds.DeliveryReceive

const AddSampleDelivery = ({
    samples,
    onDelete,
    onCreateDeliverySample,
    onAddNewDelivery,
    onDeliveryTypeChange,
    onBarCodeChange,
    onRefreshHandler,
    onBarcode,
    t,
    currentTab,
    onCreateDeliveryTest,
    onLoad,
    sidTypeExcludeChange,
    sampleList,
    oldBarcode
}) => {

    const [tabActive, setTabActive] = useState("1")
    const userInfo = localStorage.getItem("userInfo")
    const { family_name, given_name, name, sub, company } = JSON.parse(userInfo)
    const [deliveryType, setDeliveryType] = useState(SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt)
    const [enableBarcode, setEnableBarcode] = useState(true)
    const [confirmedTime, setConfirmedTime] = useState(new Date())
    const [itemSample, setItemSample] = useState(samples || {})
    const [isOpen, setIsOpen] = useState(true)
    const [timeSchedual, setTimeSchedual] = useState({})
    const [handlerUserId, setHandlerUserId] = useState({ value: sub, name: `${family_name} ${given_name}` })
    const [targetUserId, setTargetUserId] = useState({ value: sub, name: `${family_name} ${given_name}` })
    const [warningReasonModal, setWarningReasonModal] = useState([])
    const [testSample, setTestSample] = useState({})

    let typeSid = sampleList.map(item => ({ label: item.sampleType, value: item.sampleType }))
    typeSid = _.uniqBy(typeSid, "value")

    const ref = useRef()

    let handlerUserName = ''
    let confirmedName = ''
    let bageName = ''
    let confirmedUserId = ''
    let confirmedUserName = ''
    let targetUserName = ''

    switch (deliveryType) {
        case SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt:
            handlerUserName = 'Receiver Name'
            confirmedName = 'DeliveryTime'
            bageName = 'DeliveryAndReceipt'
            targetUserName = 'Sender Name'
            confirmedUserId = handlerUserId.value
            confirmedUserName = handlerUserId?.name
            break
        case SAMPLES_DELIVERY_TYPE.Correction:
            handlerUserName = `${t('Receiver Name')} ${t('Broken')}`
            confirmedName = 'ReturnTime'
            bageName = 'Return Sample'
            targetUserName = 'ReturnName'
            confirmedUserId = targetUserId?.value
            confirmedUserName = targetUserId?.name
            break
        case SAMPLES_DELIVERY_TYPE.Cancelation:
            handlerUserName = 'CancelName'
            confirmedName = 'CancelTime'
            bageName = 'CancelSampleInfo'
            targetUserName = 'Sender Name'
            confirmedUserId = handlerUserId?.value
            confirmedUserName = handlerUserId?.name
            break
    }

    useEffect(() => {
        if (samples) {
            samples.forEach((item) => {
                item.confirmedUserId = confirmedUserId
                item.confirmedUserName = confirmedUserName
                item.quality = deliveryType === SAMPLES_DELIVERY_TYPE.Correction ? 'B' : 'G'
                item.qualityName = deliveryType === SAMPLES_DELIVERY_TYPE.Correction ? t('Not Good') : t('Good')
            })
            setItemSample(samples)
        } else {
            setItemSample([])
        }
    }, [samples])

    useEffect(() => {
        setDeliveryType(currentTab)
    }, [currentTab])

    useEffect(() => {
        itemSample.forEach((item) => {
            item.confirmedUserId = confirmedUserId
            item.confirmedUserName = confirmedUserName
        })
        setItemSample([...itemSample])
    }, [targetUserId, handlerUserId])

    const onToggleTab = tab => {
        setTabActive(tab)
    }

    const onCreateDelivery = (data) => {
        let reasonIsEmpty = []
        if (deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) {
            reasonIsEmpty = testSample.items.filter(item => !item.reason)
            setWarningReasonModal([...reasonIsEmpty])
        }

        if (deliveryType === SAMPLES_DELIVERY_TYPE.Correction) {
            reasonIsEmpty = data.filter(item => !item.reason)
            setWarningReasonModal([...reasonIsEmpty])
        }

        if (reasonIsEmpty.length > 0) {
            return
        }

        let value = {
            deliveryTime: confirmedTime,
            type: deliveryType,
            state: DeliveryStateText.OPEN,
            companyId: company,
            handlerUserId: handlerUserId.value,
            handlerUserName: handlerUserId.name,
            targetUserId: targetUserId.value,
            targetUserName: targetUserId.name,
            isGetName: false
        }
        const sampleData = deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation ? samples : data

        let result = sampleData.reduce((unique, o) => {
            if (!unique.some(item => item.requestId === o.requestId
                && item.sampleType === o.sampleType
                && item.sid === o.sid
                && item.subSID === o.subSID)) {
                unique.push(o);
            }
            return unique;
        }, [])

        let samps = getSampleAfterCreate(result)
        value.samples = samps;

        onAddNewDelivery({
            delivery: value, callback: (delivery) => {
                onAddSample(delivery, result)
                onLoad(true)
            }
        })
    }

    const getSampleAfterCreate = (values) => {
        const result = []
        for (let index = 0; index < values.length; index++) {
            let res = {}
            const element = values[index]
            res.requestId = element.requestId
            res.patientId = element.patientId
            if (values[index].sampleType)
                res.sampleType = values[index].sampleType
            else
                res.sampleType = values[index]
            res.sid = element.sid
            res.confirmedUserId = confirmedUserId
            res.confirmedTime = element.confirmedTime
            res.reason = element.reasonName
            res.quality = element.quality
            res.subSID = element.subSID
            res.remark = element.remark
            result.push(res)
        }
        return result
    }

    const onAddSample = (delivery, values) => {
        const result = []
        for (let index = 0; index < values.length; index++) {
            let res = {}
            const element = values[index]
            res.deliveryId = delivery.id
            res.requestId = element.requestId
            res.patientId = element.patientId
            if (values[index].sampleType)
                res.sampleType = values[index].sampleType
            else
                res.sampleType = values[index]
            res.sid = element.sid
            res.subSID = element.subSID ? element.subSID : null
            res.confirmedUserId = confirmedUserId
            res.confirmedTime = element.confirmedTime
            res.reason = element.reasonName
            res.quality = element.quality
            res.remark = element.remark
            result.push(res)
        }
        onSaveSample({ deliveryId: delivery.id, samples: result }, delivery.id)
        if (deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) {
            onSaveTest(delivery.id)
        }
    }

    const onSaveSample = (values, deliveryId) => {
        onCreateDeliverySample(values, () => afterCreateMulti(values))
    }

    const afterCreateMulti = (deliveryId) => {
        onRefreshHandler()
        onLoad(false)
    }

    const onSaveTest = values => {
        const result = {}
        result.deliveryId = values
        result.reason = testSample?.reason?.code
        // result.confirmedTime = confirmedTime
        result.confirmedUserId = handlerUserId.value
        result.ConfirmedUserId = handlerUserId.value
        result.items = testSample.items.map(x => ({
            requestId: x.requestId,
            SID: x.sid,
            requestDate: x.requestDate,
            patientId: x.patientId,
            testCode: x.testCode,
            reason: x.reasonName,
        }))
        onCreateDeliveryTest(result, () => {
            onRefreshHandler()
        })
    }

    const onTypeChange = (data) => {
        setDeliveryType(data)
        onDeliveryTypeChange(data)
    }

    const onTimeChange = (value, index) => {
        timeSchedual[index] = value
        setTimeSchedual(timeSchedual)
    }

    const handleTest = (data) => {
        data.items.forEach(item => {
            // item.confirmedTime = confirmedTime
            item.confirmedUserName = handlerUserId?.name
        })
        setTestSample(() => ({ ...data }))
    }

    const onDeleteTest = (data) => {
        if (data.items && data.items.length > 0) {
            const sampleResult = samples.filter((item) =>
                data.items.findIndex(x => x.sid === item.sid
                    && x.sampleType === item.sampleType
                    && x.requestId === item.requestId) === -1
            )
            if (sampleResult.length > 0) {
                onDelete(sampleResult)
            }
        } else {
            onDelete(samples)
        }
    }

    const sidWarning = [...new Set(warningReasonModal.map(item => item.sid))].join(', ')

    const testCount = useMemo(() => {
        if (!testSample?.items) return 0;

        const uniqueSet = new Set();
        testSample.items.forEach(o => {
            const key = `${o.sid}-${o.sampleType}-${o.requestId}-${o.subSID}-${o.profileCode || o.testCode}`;
            uniqueSet.add(key);
        });

        return uniqueSet.size;
    }, [testSample]);

    return (
        <div>
            <div className={`p-3 mb-3 pt-1 ${!isOpen && 'pb-1'}`} style={{
                border: '1px solid #ced4da',
                borderRadius: '3.25px',
                boxShadow: '0px 10px 0px #f6f6f6'
            }}>
                <div className="row"
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => { setIsOpen(!isOpen) }}>
                    <div className="col-sm-10 d-flex">
                        {!isOpen &&
                            <div className="d-flex gap-3">
                                {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation && <div >
                                    <label className="mx-1 font-size-13">
                                        {t(targetUserName)}:

                                    </label>
                                    <label className="font-size-14" style={{ color: '#556ee6' }}>
                                        <b>   {` ${targetUserId.name || ''}`}</b>
                                    </label>
                                </div>}
                                <div >
                                    <label className="mx-1 font-size-13">
                                        {t(handlerUserName)}:
                                    </label>
                                    <label className="font-size-14" style={{ color: '#556ee6' }}>
                                        <b>    {` ${handlerUserId.name || ''}`}</b>
                                    </label>
                                </div>
                                <div >
                                    <label className="mx-1 font-size-13">
                                        {t(confirmedName)}:
                                    </label>
                                    <label className="font-size-13">
                                        <b>     {` ${convertDateFormat(confirmedTime)}`}</b>
                                    </label>

                                </div>
                            </div>}

                    </div>
                    <div className="col-sm-2 d-flex gap-2"
                        style={{
                            justifyContent: 'end', cursor: 'pointer',
                        }}
                    >
                        {!isOpen && <BadgeLabel
                            value={t(bageName)}
                            className="badge-width font-size-12"
                            style={{ color: 'white', backgroundColor: '#556ee6' }}
                        />}
                        <i className={isOpen ? "dripicons-chevron-up" : "dripicons-chevron-down"} />
                    </div>
                </div>

                <Collapse isOpen={isOpen} className="accordion-collapse">
                    <AvForm>
                        <div className="d-flex gap-4 pt-0 pb-0" style={{ alignItems: 'center', display: !isOpen && 'none' }}>
                            <div>
                                <div >
                                    <div type="button" className={`px-3 btn ${(!enableBarcode) ? 'btn-outline-primary' : 'btn-primary'}`}
                                        onClick={() => {
                                            setEnableBarcode(prev => !prev)
                                            onBarCodeChange(!enableBarcode)
                                        }}
                                    >
                                        <i className="fa fa-barcode "></i>
                                        <div>{t("Barcode")}</div>
                                    </div>
                                    {enableBarcode &&
                                        <div style={{ textAlign: 'center' }} >
                                            <strong>{oldBarcode}</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3" style={{ marginTop: 'auto' }}>
                                <CustomMultiSelectAsync
                                    name="sampleExclue"
                                    // isClearable={false}
                                    options={typeSid || []}
                                    label={t("Sample Exclude")}
                                    onChange={(e, values, label, value, data, unSelected) => {
                                        sidTypeExcludeChange(values)
                                    }}
                                    isMulti={true}
                                    placeholder={t("Sample Exclue")}
                                />
                            </div>
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation && <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                    <div style={{ width: '100%' }}>
                                        <CustomAutoComplete
                                            name="targetUserId"
                                            isClearable={false}
                                            required
                                            label={t(targetUserName)}
                                            value={targetUserId.value || ''}
                                            code={parameterCode.USERS}
                                            onChange={(name, value, item) => {
                                                if (typeof value[0] === 'string') {
                                                    setTargetUserId({ ...targetUserId, name: value[0] })
                                                } else {
                                                    setTargetUserId({ name: item[0]?.label, value: item[0]?.id })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="col-xl-2">
                                <div className="d-flex align-items-center gap-2">
                                    <div style={{ width: '100%' }}>
                                        <CustomAutoComplete
                                            name="handlerUserId"
                                            isClearable={false}
                                            required
                                            value={handlerUserId.value || ''}
                                            code={parameterCode.USERS}
                                            label={t(handlerUserName)}
                                            onChange={(name, value, item) => {
                                                if (typeof value[0] === 'string') {
                                                    setHandlerUserId({ ...handlerUserId, name: value[0] })
                                                } else {
                                                    setHandlerUserId({ name: item[0]?.label, value: item[0]?.id })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Check permission={permissionType.DeliveryTime} resource={RESOURCE}>
                                <div className="col-xl-2">
                                    <div className="d-flex gap-2 align-items-center">
                                        <div style={{ width: '100%' }}>
                                            <CustomDatePicker
                                                label={t(confirmedName)}
                                                errorMessage=''
                                                name="confirmedTime"
                                                placeholder={t("Confirmed Time")}
                                                value={confirmedTime || new Date()}
                                                // readOnly={enableBarcode}
                                                // disabled={enableBarcode}
                                                enableTime={true}
                                                dateFormat='Y-m-d H:i'
                                                onChangeHandler={(e) => {
                                                    setConfirmedTime(e.time[0])
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Check>
                            {/* <div className="d-flex gap-1 align-self-end">
                                {(deliveryType === SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt) &&
                                    <>
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            id={`btn-check-outlined-cancel`}
                                            checked
                                        ></input>
                                        <label
                                            className="btn btn-outline-primary"
                                            htmlFor={`btn-check-outlined-cancel`}
                                        >
                                            {t(bageName)}
                                        </label>
                                    </>

                                }
                                {(deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation || deliveryType === SAMPLES_DELIVERY_TYPE.Correction) &&
                                    <>
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            id={`btn-check-outlined-cancel`}
                                            checked
                                        ></input>
                                        <label
                                            className="btn btn-outline-primary"
                                            htmlFor={`btn-check-outlined-cancel`}
                                        >
                                            {t(bageName)}
                                        </label>
                                    </>}
                            </div> */}
                        </div>
                    </AvForm>
                </Collapse>
            </div >
            <CustomNav
                defaultTab={tabActive}
                tabs
                className="nav-tabs-custom"
                onToggle={onToggleTab}
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                        className="p-3 pt-0 text-muted"
                    >
                        <TabPane tabId="1">
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabSample
                                    samples={itemSample}
                                    onDelete={onDelete}
                                    deliveryType={deliveryType}
                                    onSubmit={onCreateDelivery}
                                    onRefreshHandler={onRefreshHandler}
                                    isCollapse={isOpen}
                                    enableBarcode={enableBarcode}
                                />}
                            {deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabTest
                                    handleTest={handleTest}
                                    samples={itemSample}
                                    deliveryType={deliveryType}
                                    isCollapse={isOpen}
                                    onSubmit={onCreateDelivery}
                                    onDelete={onDeleteTest}
                                    handlerUserId={handlerUserId}
                                    enableBarcode={enableBarcode}
                                />}
                        </TabPane>
                        <TabPane tabId="2">
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabTest
                                    handleTest={handleTest}
                                    samples={itemSample}
                                    deliveryType={deliveryType}
                                    isCollapse={isOpen}
                                    enableBarcode={enableBarcode}
                                />}
                            {deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabSample
                                    samples={itemSample}
                                    onDelete={onDelete}
                                    deliveryType={deliveryType}
                                    onSubmit={onCreateDelivery}
                                    onRefreshHandler={onRefreshHandler}
                                    isCollapse={isOpen}
                                    ref={ref}
                                    enableBarcode={enableBarcode}
                                />
                            }
                        </TabPane>
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <div className="d-flex gap-2">
                        <span >{deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? t("Sample") : t("Tests")}</span>
                        <span className="badge-gender"
                            style={{ width: '29px', backgroundColor: "#39a8fd", color: "white", textAlign: '-webkit-center', fontSize: '14px', alignItems: 'center' }}>
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? itemSample?.length : (testCount || 0)}
                        </span>
                    </div>
                </CustomNavLink>

                <CustomNavLink tabId="2">
                    <div className="d-flex gap-2">
                        <span >{deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? t("Tests") : t("Sample")}</span>
                        <span
                            className="badge-gender"
                            style={{
                                width: '29px',
                                backgroundColor: "rgb(202 232 255)",
                                color: "blue",
                                textAlign: '-webkit-center',
                                fontSize: '14px',
                                borderWidth: '1.5px',
                                borderStyle: 'solid',
                                borderColor: '#6fb6ff',
                                alignItems: 'center'
                            }}>
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? (testCount || 0) : itemSample?.length}
                        </span>
                    </div>
                </CustomNavLink>

            </CustomNav>
            <WarningModal
                modal={warningReasonModal.length > 0}
                onToggle={() => setWarningReasonModal([])}
                message={`${t("message:PleaseSelectError")} ${t("deliveryPage:reason")} SID: ${sidWarning}`}
            />
        </div >
    )
}

const mapStateToProps = ({ sampleDelivery }) => ({
    loadingSamples: sampleDelivery.loadingSamples,
    updatedSampleTime: sampleDelivery.updatedSampleTime,
})

const mapDispatchToProps = dispatch => ({
    onCreateDeliverySample: (sample, callback) => dispatch(addDeliverySample({ sample, callback })),
    onAddNewDelivery: (delivery) =>
        dispatch(addDelivery(delivery)),
    onCreateDeliveryTest: (test, callback) =>
        dispatch(addDeliveryTest({ test, callback })),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["deliveryPage", "common", "message"])(AddSampleDelivery))