import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import FilterForm from "./FilterForm";
import { CustomButton, CustomSID, FilterButton } from "components/Common";
import { ModuleIds } from "constant";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";

const RESOURCE = ModuleIds.PatientVisit;

const ToCollectPatient = ({ data, t, onSelectPatient, activeTab,
  onResetHandler,
  onSubmitFilterPatient,
  pagingCollected,
  patientDetail,
  model }) => {

  let countTotal = 0
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (loading !== null) {
      setLoading(!loading)
    }
  }, [data])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return (
            <span id={`watch_end_of_document4`}></span>
          )
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("patientVisitPage:Date"),
      style: { textAlign: 'left', width: '120px' },
      headerStyle: { textAlign: 'left' },
      formatter: (cellContent, item, index) => {
        return convertDateFormat(item.createdDate, "YYYY-MM-DD")
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      formatter: (cellContent, item, index) => {
        const data = item?.sampleIds ? JSON.parse(item?.sampleIds) : []
        const removeDuplicate = data.filter(
          (element, index) => index === data.findIndex(
            other => element.SID === other.SID
          ))
        return item.sampleIds &&
          removeDuplicate.map((x, postion) => <CustomSID
            key={`${index}-${postion}-collect`}
            keyToolTip={`${index}-${postion}-collect`}
            isToolTip={true}
            toolTip={x?.SampleTypeName}
            sid={x?.SID}
            emergency={item?.emergency}
          />
          )
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex">
            {item?.groupCode && <i
              key={index}
              data-tip
              data-for={`groupCode-${index}`}
              className="mdi mdi-bookmark"
              style={{
                fontSize: 'larger',
                color: 'purple'
              }}
            >
              {item.organizationName && <ReactTooltip id={`groupCode-${index}`} place="top" effect="solid">
                {item.organizationName || ''}
              </ReactTooltip>}
            </i>}
          </div>
        )
      }
    },
    {
      dataField: "patientName",
      text: t("Name"),
      style: { textAlign: 'left', width: '200px' },
      headerStyle: { textAlign: 'left' },
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.patientName}</div>
        )
      }
    },
    {
      dataField: "age",
      text: t("DOB"),
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, item, index) => item.dob && <div>{convertDateFormat_VN(item.dob, "DD-MM-YYYY")}</div>
      ,
    }
  ]

  const getDataDisplay = () => {
    let res = [...data]
    res.push({ id: -1, testCode: '' })
    countTotal = res.length
    return res
  }

  const rowClasses = (row, rowIndex) => {
    return row.id === patientDetail?.id ? "patient-select" : ""
  }

  return (
    <div className={`table-patient-visit ${data.length > 0 ? 'table-expand-custom table-rule-management' : ''}  `} style={{ height: '100%' }}>
      <CustomBootstrapTableAsync
        TableTitle={t('Patient List')}
        columns={columns}
        data={getDataDisplay()}
        keyField="id"
        onRowClick={onSelectPatient}
        showSelectRow={false}
        paging={pagingCollected}
        resource={RESOURCE}
        isScrollable={true}
        rowClasses={rowClasses}
        style={{ height: '260px' }}
        onRefresh={() => {
          onResetHandler('3')
          setLoading(true)
        }}
        loading={loading}
        customButtonRight={() => (
          <FilterButton
            onReset={onResetHandler}
            onSubmit={onSubmitFilterPatient}
            direction={'end'}
            className={''}
          >
            <FilterForm model={model} />
          </FilterButton>
        )}
      />
    </div>
  )
}
export default withTranslation(["testRequestPage", "message", "patientVisitPage"])(ToCollectPatient)
