import { CustomBootstrapTable, CustomSID } from "components/Common";
import IconStatus from "components/Common/IconStatus";
import * as constant from "constant";
import { convertDateFormat, convertDateFormat_VN, indexCalculator } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FilterForm from "./FilterForm";

const RESOURCE = constant.ModuleIds.WorkList;

const WorkListTable = ({
    workLists,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeModel,
    buttons,
    onSelect,
    onSelectAll,
    onExport,
    loadingDataExport,
    onExportWorkList,
}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(workLists)
    }, [workLists])

    const columns = [
        {
            dataField: "idx",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: t("#"),
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "sid",
            text: t("SID"),
            formatter: (cellContent, item, index) => {
                return <CustomSID
                    sid={item.sid}
                    textOnly={true}
                />
            }
        },
        {
            dataField: "fullName",
            text: t("Patient Name"),
            formatter: (cellContent, row, index) => {
                return row.fullName ? <Link to={`/Patient/${row.patientId}/view?tab=1`} target="_blank">{`${row.fullName}`}</Link> : <></>
            },
        },
        {
            dataField: "dob",
            text: t("DOB"),
            formatter: (cellContent, row, index) => {
                return convertDateFormat_VN(cellContent)
            },
        },
        {
            dataField: "patientId",
            text: t("PID"),
        },
        {
            dataField: "testName",
            text: t("Test Name"),
        },
        {
            dataField: "result",
            text: t("Result"),
        },
        {
            dataField: "resultText",
            text: t("Result Text"),
        },
        {
            dataField: "normalRange",
            text: t("Normal Range"),
        },
        {
            dataField: "remark",
            text: t("Remark"),
        },
        {
            dataField: "valid",
            text: t("Valid"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, row, index) => {
                return <IconStatus
                    isRound={true}
                    type={row.validatedBy != null ? "Active" : "Inactive"}
                />
            },
        },
        {
            dataField: "validatedByName",
            text: t("User Valid"),
            formatter: (cellContent, row, index) => {
                return (
                    <Link to={`/User/${row.validatedBy}/view?tab=1`} target="_blank">{cellContent}</Link>
                )
            },
        },
        {
            dataField: "validatedDate",
            text: t("Valid Time"),
            formatter: (cellContent, row, index) => {
                return convertDateFormat(row.validatedDate, "YYYY-MM-DD")
            },
        },
        {
            dataField: "createdByName",
            text: t("User Input"),
            formatter: (cellContent, row, index) => {
                return (
                    <Link to={`/User/${row.createdBy}/view?tab=1`} target="_blank">{cellContent}</Link>
                )
            },
        },
    ]

    return (
        <React.Fragment>
            <div className="work-list">
                <FilterForm onChangeModel={(model) => {
                    onChangeModel(model)
                }}
                    loadingDataExport={loadingDataExport}
                    onSearch={onSearch}
                    onExport={onExportWorkList}
                />
            </div>
            <CustomBootstrapTable
                columns={columns}
                data={data}
                paging={paging}
                onSort={onSort}
                onRefresh={onRefresh}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isEnableRefresh={false}
                isEnableExport={true}
                customButtonTable={() => <React.Fragment>
                    {buttons && buttons()}
                </React.Fragment>}
                showSelectRow={true}
                onSelect={onSelect}
                isSelected
                onSelectAll={onSelectAll}
            />

        </React.Fragment>
    )
}

export default withTranslation(["workListPage", "common"])(WorkListTable)