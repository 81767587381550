import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CustomAvInput, CustomBootstrapTable, CustomSelect } from "components/Common"
import { ConvertStringToBoolean, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm";

//i18n
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import * as constant from "constant"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import ImportExportButton from "./ImportExportForm"
import { AvForm } from "availity-reactstrap-validation";
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon";
import FormSwitchForm from "components/Common/FormSwitchForm";
import { UpdateAdditionalInfoTestField } from "helpers/app-backend";

const RESOURCE = constant.ModuleIds.Test;

const TestTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    onClone,
    loading,
    t,
    updatedTime,
    onDisplayOrderChange,
    onAddTestList,
    onDeleteVendor,
    updateInfoTest
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        type: "",
        category: "",
        sampleType: "",
    });
    const searchQuery = useSelector(state => state.test.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: "",
            category: "",
            sampleType: "",
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        let tmp = tests || [];
        tmp.forEach(element => {
            let notPrint = false;
            let notStatistical = false;
            if (element.addtionalInfos && element.addtionalInfos != '') {
                let adds = JSON.parse(element.addtionalInfos)
                let indexNotPrint = adds.findIndex(x => x.FieldCode == constant.ADDITIONAL_FIELD.NotPrint)
                if (indexNotPrint >= 0) {
                    notPrint = ConvertStringToBoolean(adds[indexNotPrint].Value)
                    element.notPrint_AdditionalFieldId = adds[indexNotPrint].AdditionalFieldId;
                }

                let indexNotStatistical = adds.findIndex(x => x.FieldCode == constant.ADDITIONAL_FIELD.NotStatistical)
                if (indexNotStatistical >= 0) {
                    notStatistical = ConvertStringToBoolean(adds[indexNotStatistical].Value)
                    element.notStatistical_AdditionalFieldId = adds[indexNotStatistical].AdditionalFieldId;
                }
            }
            else {
                notPrint = false;
                notStatistical = false
            }
            if (notPrint) {
                element.notPrint = notPrint;
            }
            if (notStatistical) {
                element.notStatistical = notStatistical
            }
        });
        setData(tmp)
    }, [tests])

    const ChangeAddtionalInfo = (value, field, index) => {
        let tmp = data;
        if (field == constant.ADDITIONAL_FIELD.NotPrint) {
            tmp[index].notPrint = !value;
        }
        else if (field == constant.ADDITIONAL_FIELD.NotStatistical) {
            tmp[index].notStatistical = !value;
        }
        setData(tmp)
    }
    const UpdateAdditionalInfoField = async (test, value, field, index) => {
        let res = await UpdateAdditionalInfoTestField({
            TestCode: test.testCode,
            FieldCode: field,
            Value: `${!value}`
        })
        ChangeAddtionalInfo(value, field, index)
    }
    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "code",
            text: t(""),
            sort: false,
            // style: { maxWidth: "40px" },
            formatter: (cellContent, test, index) => {
                return <div style={{ display: "flex", gap: 1 }}>
                    {test.profileId?.split(',').map((item, position) =>
                        item != "" ?
                            <div key={position}>
                                <i
                                    data-tip
                                    data-for={`${item}-${position}`}
                                    className="fas fa-dot-circle"
                                    style={{ color: 'green' }}
                                    onClick={() => {
                                        const link = `TestProfile/${item || ''}/view?tab=1`
                                        window.open(link)
                                    }}
                                />

                                <ReactTooltip id={`${item}-${position}`} place="right" effect="solid">
                                    {test.profileName.split(',')[position]}
                                </ReactTooltip>
                            </div>
                            : <React.Fragment key={index}></React.Fragment>
                    )}
                </div>

            },
        },
        {
            dataField: "testCode",
            text: t("Code"),
            sort: true,
            formatter: (cellContent) => {
                return <span style={{ fontWeight: 'bold' }}>{cellContent}</span>
            }
        },
        {
            dataField: "testName",
            text: t("Name"),
            style: { maxWidth: "300px" },
            sort: true,
            formatter: (cellContent, test, index) => {
                const testName = test?.customName && test.customName !== '' ? `${test.testName} [${test.customName}]` : test.testName
                return <Link to={`/Test/${test.id}/view?tab=1`}>{testName}</Link>
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            sort: true,
        },
        {
            dataField: "reportTypeName",
            text: t("Report Type"),
            style: { width: "140px" },
            sort: false,
            // formatter: (cellContent, test, index) => {
            //     return <span>{cellContent && t(`common:${cellContent}`)}</span>
            // },
            formatter: (cellContent, test, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`reportType-test-${index}`).style.display = ""
                            document.getElementById(`reportType-test-${index}`).focus()
                            document.getElementById(`reportType-test-text-${index}`).style.display = "none"
                        }}
                    >
                        <a
                            href="#"
                            id={`reportType-test-text-${index}`}
                        >{test.reportTypeName || '---'}</a>
                        <AvForm>
                            <div id={`reportType-test-${index}`}
                                style={{ display: 'none' }}
                                onBlur={() => {
                                    document.getElementById(`reportType-test-${index}`).style.display = "none"
                                    document.getElementById(`reportType-test-text-${index}`).style.display = ""
                                }}
                            >
                                <CustomSelect
                                    isClearable={false}
                                    name="reportType"
                                    value={test.reportType || ''}
                                    code={constant.parameterCode.TEST_TYPES}
                                    onChange={(name, value) => {
                                        if (value[0] != test.reportType) {
                                            test.reportType = value[0]
                                            updateInfoTest(test)
                                        }
                                        document.getElementById(`reportType-test-${index}`).style.display = "none"
                                        document.getElementById(`reportType-test-text-${index}`).style.display = ""
                                    }}
                                />
                            </div>
                        </AvForm>
                    </div>
                )
            },
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            sort: true,
            style: { width: "140px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
            formatter: (cellContent, test, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`sampleType-test-${index}`).style.display = ""
                            document.getElementById(`sampleType-test-${index}`).focus()
                            document.getElementById(`sampleType-test-text-${index}`).style.display = "none"
                        }}
                    >
                        <a
                            href="#"
                            id={`sampleType-test-text-${index}`}
                        >{test.sampleTypeName || '---'}</a>
                        <AvForm>
                            <div id={`sampleType-test-${index}`}
                                style={{ display: 'none' }}
                                onBlur={() => {
                                    document.getElementById(`sampleType-test-${index}`).style.display = "none"
                                    document.getElementById(`sampleType-test-text-${index}`).style.display = ""
                                }}
                            >
                                <CustomSelect
                                    autoFocus
                                    isClearable={false}
                                    name="sampleType"
                                    value={test.sampleType || ''}
                                    code={constant.parameterCode.SAMPLE_TYPES}
                                    onChange={(name, value) => {
                                        if (value[0] != test.sampleType) {
                                            test.sampleType = value[0]
                                            updateInfoTest(test)
                                        }
                                        document.getElementById(`sampleType-test-${index}`).style.display = "none"
                                        document.getElementById(`sampleType-test-text-${index}`).style.display = ""
                                    }}
                                />
                            </div>
                        </AvForm>
                    </div>
                )
            },
        },
        {
            dataField: "subSID",
            text: t("Sub SID"),
            sort: true,
            style: { width: "110px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
            formatter: (cellContent, test, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`subSid-test-${index}`).style.display = ""
                            document.getElementById(`subSid-test-${index}`).focus()
                            document.getElementById(`subSid-test-text-${index}`).style.display = "none"
                        }}
                    >
                        <a
                            href="#"
                            id={`subSid-test-text-${index}`}
                        >{cellContent || '---'}</a>
                        <AvForm>
                            <CustomAvInput
                                style={{ display: 'none' }}
                                id={`subSid-test-${index}`}
                                name={`subSid`}
                                value={test.subSID || ''}
                                type='number'
                                min={10}
                                max={99}
                                onBlur={(e) => {
                                    if ((e.target.value != (test.subSID || '') && e.target.value >= 10 && e.target.value <= 99) || e.target.value == '') {
                                        test.subSID = e.target.value
                                        updateInfoTest(test)
                                    }
                                    document.getElementById(`subSid-test-${index}`).style.display = "none"
                                    document.getElementById(`subSid-test-text-${index}`).style.display = ""
                                }}
                            />
                        </AvForm>
                    </div>
                )
            },
        },
        {
            dataField: "unit",
            text: t("Unit"),
            sort: true,
            style: { maxWidth: "70px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
            formatter: (cellContent, test, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`unit-test-${index}`).style.display = ""
                            document.getElementById(`unit-test-${index}`).focus()
                            document.getElementById(`unit-test-text-${index}`).style.display = "none"
                        }}
                    >
                        <a
                            href="#"
                            id={`unit-test-text-${index}`}
                        >{cellContent || '---'}</a>
                        <AvForm>
                            <CustomAvInput
                                style={{ display: 'none' }}
                                id={`unit-test-${index}`}
                                name={`unit`}
                                value={test.unit}
                                onBlur={(e) => {
                                    if (e.target.value != test.unit) {
                                        test.unit = e.target.value
                                        updateInfoTest(test)
                                    }
                                    document.getElementById(`unit-test-${index}`).style.display = "none"
                                    document.getElementById(`unit-test-text-${index}`).style.display = ""
                                }}
                            />
                        </AvForm>
                    </div>
                )
            },
        },
        {
            dataField: "displayOrder",
            text: t("Display Order"),
            sort: true,
            style: { maxWidth: "100px" },
            align: '-webkit-center',
            headerAlign: '-webkit-center',
            formatter: (cellContent, test, index) => {
                return (
                    <div
                        onClick={e => {
                            document.getElementById(`displayOrder-test-${index}`).style.display = ""
                            document.getElementById(`displayOrder-test-${index}`).focus()
                            document.getElementById(`displayOrder-test-text-${index}`).style.display = "none"
                        }}
                    >
                        <a
                            href="#"
                            id={`displayOrder-test-text-${index}`}
                        >{cellContent}</a>
                        <AvForm>
                            <CustomAvInput
                                style={{ display: 'none' }}
                                id={`displayOrder-test-${index}`}
                                name={`displayOrder`}
                                value={test.displayOrder || ''}
                                type='number'
                                min={0}
                                onBlur={(e) => {
                                    if (e.target.value != test.displayOrder) {
                                        onDisplayOrderChange(test, e.target.value)
                                    }
                                    document.getElementById(`displayOrder-test-${index}`).style.display = "none"
                                    document.getElementById(`displayOrder-test-text-${index}`).style.display = ""
                                }}
                            />
                        </AvForm>
                    </div>
                )
            },
        },
        {
            dataField: "vendorId",
            text: t("Vender Code"),
            formatter: (cellContent, test, index) => {
                return <AvForm>
                    <FormSwitchForm
                        name="vendorId"
                        label=''
                        value={!!cellContent}
                        disabled={!cellContent}
                        onChange={() => { onDeleteVendor(test.id) }}
                    />
                </AvForm>
            },
        },
        // {
        //     dataField: "notPrint",
        //     text: t("Not Print"),
        //     formatter: (cellContent, test, index) => {
        //         return <AvForm>
        //             <FormSwitchForm
        //                 name="notPrint"
        //                 label=''
        //                 value={!!cellContent}
        //                 onChange={() => { UpdateAdditionalInfoField(test, !!cellContent, constant.ADDITIONAL_FIELD.NotPrint, index) }}
        //             />
        //         </AvForm>
        //     },
        // },
        // {
        //     dataField: "notStatistical",
        //     text: t("Not Statistical"),
        //     formatter: (cellContent, test, index) => {
        //         return <AvForm>
        //             <FormSwitchForm
        //                 name="notStatistical"
        //                 label=''
        //                 value={!!cellContent}
        //                 onChange={() => { UpdateAdditionalInfoField(test, !!cellContent, constant.ADDITIONAL_FIELD.NotStatistical, index) }}
        //             />
        //         </AvForm>
        //     },
        // },
        {
            dataField: "inUse",
            text: t("Status"),
            // sort: true,
            style: { minWidth: "85px" },
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, test, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent ? "Active" : "Inactive"}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            style: { width: 50 },
            formatter: (cellContent, param, index) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div className="d-flex toolbar button-items text-end" style={{ marginRight: '8px' }}>
                            <CustomButtonIcon
                                id={`test-list-${index}`}
                                color="btn btn btn-outline-warning btn-soft-warning"
                                disabled={param?.vendorCode}
                                data-tip
                                data-for={`test-list-${index}`}
                                onClick={() => { onAddTestList(param) }}>
                                <i className="mdi mdi-plus" />
                            </CustomButtonIcon>
                            <ReactTooltip place="top" id={`test-list-${index}`} effect="solid">
                                {t("Add test send")}
                            </ReactTooltip>
                        </div>
                        <CustomTooltipButton
                            index={index}
                            component={param}
                            onClone={onClone}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            t={t}
                        />
                    </div>

                )
            },
        },
    ]

    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                // isSelected
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isImportExportButton={true}
                importExportForm={() => <ImportExportButton />}
            />

        </div>
    )
}

export default withTranslation(["testPage"])(TestTable)