import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { getAllOrganizations } from "helpers/app-backend"
import { convertDateFormat } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const TestRequestTableButtons = ({
  model, onChange, onSubmit, t
}) => {

  const [groups, setGroups] = useState([])
  useEffect(() => {
    getGroups()
  }, [])
  const getGroups = async () => {
    let res = await getAllOrganizations({ size: 0 })
    if (res && res.data) {
      setGroups(res.data.map(x => ({ label: x.name, value: x.organizationCode })))
    }
  }

  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{ marginLeft: '5px' }}>
            <CustomDatePicker
              conjunction=" - "
              name="requestDate"
              label={""}
              checkSameTime={true}
              format={"d-m-Y"}
              value={[model.start || new Date(), model.end || new Date()]}
              closeOnSelect={true}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  onSubmit && onSubmit()
                }
              }}
              onChangeHandler={date => {
                if (date.time.length == 2) {
                  // setDates([convertDateFormat(date.time[0], "YYYY-MM-DD"), convertDateFormat(date.time[1], "YYYY-MM-DD")])
                  onChange({
                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                  })
                  setTimeout(() => {
                    onSubmit({
                      ...model,
                      start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                      end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                    })
                  }, 100)
                }       
                else {
                  // setDates([new Date(), new Date()])
                  onChange({
                    start: new Date(),
                    end: new Date(),
                  })
                }
              }}
              maxDate={new Date()}
              // minDate={minDate}
              mode="range"
            />
          </div>
          <div style={{ width: '200px', marginLeft: '5px' }}>
            <CustomSelect
              label={""}
              placeholder={t("State")}
              portal
              name="state"
              value={model.state || ''}
              code={parameterCode.TESTREQUEST_STATE_KEY}
              onChange={(name, value, label, item) => {
                onChange({ state: value[0] || null })
                setTimeout(() => {
                  onSubmit({ ...model, state: value[0] || null })
                }, 100);
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: '5px' }}>
            <CustomSelect
              label={""}
              options={groups}
              placeholder={t("Group/Company")}
              portal
              name="groupCode"
              value={model.groupCode}
              code={''}
              onChange={(name, value, label, item) => {
                onChange({ groupCode: value[0] || null })
                setTimeout(() => {
                  onSubmit({ ...model, groupCode: value[0] || null })
                }, 100);
              }}
            />
          </div>
        </div>
      </AvForm>

    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({

})

const mapDispatchToProps = dispatch => ({

})

TestRequestTableButtons.displayName = 'TestRequestTableButtons';
export default withTranslation(["testRequestPage", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(TestRequestTableButtons))
