import {
    GET_WORK_LIST, GET_WORK_LIST_FAIL, GET_WORK_LIST_SUCCESS,
    UPDATE_WORK_LIST, UPDATE_WORK_LIST_SUCCESS, UPDATE_WORK_LIST_FAIL,
    INVALID_WORK_LIST, INVALID_WORK_LIST_FAIL, INVALID_WORK_LIST_SUCCESS,
    VALID_WORK_LIST, VALID_WORK_LIST_FAIL, VALID_WORK_LIST_SUCCESS,
    GET_WORK_LIST_EXPORT,
    GET_WORK_LIST_EXPORT_FAIL,
    GET_WORK_LIST_EXPORT_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    workLists: [],
    paging: {},
    workList: {},
    loadingData: false,
    updateDataTime: new Date(),
    updatingWorkList: false,
    loadingDataExport: false,
}

const WorkList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_WORK_LIST:
            return {
                ...state,
                error: {},
                workLists: [],
                loadingData: true,
            }

        case GET_WORK_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingData: false,
            }

        case GET_WORK_LIST_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    workLists: data,
                    paging: { ...rest, dataSize: totalElements },
                    loadingData: false,
                    updateDataTime: new Date()
                }
            }

        case UPDATE_WORK_LIST:
            return {
                ...state,
                updatingWorkList: true,
                error: {},
            }

        case UPDATE_WORK_LIST_SUCCESS:
            return {
                ...state,
                updatingWorkList: false,
            }

        case UPDATE_WORK_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingWorkList: false,
            }

        case VALID_WORK_LIST:
            return {
                ...state,
                updatingWorkList: true,
                error: {},
            }

        case VALID_WORK_LIST_SUCCESS:
            return {
                ...state,
                updatingWorkList: false,
            }

        case VALID_WORK_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingWorkList: false,
            }

        case INVALID_WORK_LIST:
            return {
                ...state,
                updatingWorkList: true,
                error: {},
            }

        case INVALID_WORK_LIST_SUCCESS:
            return {
                ...state,
                updatingWorkList: false,
            }

        case INVALID_WORK_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingWorkList: false,
            }

        case GET_WORK_LIST_EXPORT:
            return {
                ...state,
                loadingDataExport: true,
            }

        case GET_WORK_LIST_EXPORT_FAIL:
            return {
                ...state,
                loadingDataExport: false,
            }

        case GET_WORK_LIST_EXPORT_SUCCESS:
            {
                return {
                    ...state,
                    loadingDataExport: false,
                }
            }
        default:
            return state
    }
}

export default WorkList;