import i18n from "i18next";
import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_WORK_LIST, GET_WORK_LIST_EXPORT, INVALID_WORK_LIST, UPDATE_WORK_LIST, VALID_WORK_LIST
} from "./actionTypes";

import { getWorkListsExportFail, getWorkListsExportSuccess, getWorkListsFail, getWorkListsSuccess, updateWorkListsFail, updateWorkListsSuccess } from "./actions";
import { getTestWorkLists, inValidWorkLists, updateWorkLists, validWorkLists } from "helpers/app-backend/workList_backend_helper";
import { convertDateFormat, ifNull } from "helpers/utilities";
import { showToast } from "components/Common";
import { getAllUsers } from "helpers/app-backend";

const t = (msg, param) => i18n.t(msg, param)
function* fetchWorkLists({ payload, callback }) {
    try {
        payload.fromDate = convertDateFormat(payload?.fromDate || new Date(), "YYYY-MM-DD HH:mm")
        payload.toDate = convertDateFormat(payload?.toDate || new Date(), "YYYY-MM-DD HH:mm")
        if (payload.testName)
            delete payload.testName
        const response = yield call(getTestWorkLists, payload)

        const arrUserCreate = response.data.filter(x => x.createdBy).map(x => x.createdBy) || [];
        const arrUserValid = response.data.filter(x => x.validatedBy).map(x => x.validatedBy) || [];
        const arrUser = [...arrUserCreate, ...arrUserValid]
        const arrUserIdDistinct = arrUser.filter((x, index) => !arrUser.includes(x, index + 1)) || []
        let resUser = [];
        if (arrUserIdDistinct.length > 0)
            resUser = yield call(getAllUsers, { id: arrUserIdDistinct })

        let res = response.data || [];
        if (resUser.data?.length > 0) {
            res.forEach((element, _index) => {
                const itemUserCreate = resUser.data?.find(x => x.id == element.createdBy)
                const itemUserValid = resUser.data?.find(x => x.id == element.validatedBy)
                if (itemUserCreate)
                    element.createdByName = "".concat(ifNull(itemUserCreate.familyName), ifNull(" " + itemUserCreate.givenName))
                if (itemUserValid)
                    element.validatedByName = "".concat(ifNull(itemUserValid.familyName), ifNull(" " + itemUserValid.givenName))
            })
        }
        response.data = res.map(x => ({ ...x, id: x.resultTestId }))
        if (callback) {
            yield put(getWorkListsExportSuccess(response))
            callback(response.data)
        }
        else {
            yield put(getWorkListsSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getWorkListsFail(error))
        yield put(getWorkListsExportFail(error))
    }
}

function* onUpdateWorkList({ payload: { filter, callback, newResult, newResultText, selectedRole, id } }) {
    try {
        const data = {
            filter, newResult, newResultText, selectedRole, id
        }
        const response = yield call(updateWorkLists, data)
        yield put(updateWorkListsSuccess())

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("workListPage:Update Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateWorkListsFail(error))
    }
}

function* onValidWorkList({ payload: { filter, callback, newResult, id } }) {
    try {
        const data = {
            filter, newResult, id
        }
        const response = yield call(validWorkLists, data)
        yield put(updateWorkListsSuccess())

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("workListPage:Valid Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback()
        yield put(updateWorkListsFail(error))
    }
}

function* onInvalidWorkList({ payload: { filter, callback, result, id } }) {
    try {
        const data = {
            filter, result, id
        }
        const response = yield call(inValidWorkLists, data)
        yield put(updateWorkListsSuccess())

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("workListPage:In Valid Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback()
        yield put(updateWorkListsFail(error))
    }
}

function* workListSaga() {
    yield takeEvery(GET_WORK_LIST, fetchWorkLists)
    yield takeEvery(GET_WORK_LIST_EXPORT, fetchWorkLists)
    yield takeEvery(UPDATE_WORK_LIST, onUpdateWorkList)
    yield takeEvery(VALID_WORK_LIST, onValidWorkList)
    yield takeEvery(INVALID_WORK_LIST, onInvalidWorkList)

}
export default workListSaga