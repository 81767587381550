import {
  ConfirmModal2,
  CustomButton,
  TitleAndTable,
  WarningModal
} from "components/Common"
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"
import { ModuleIds, NornalRuleType, RuleManagementType, RuleTestType, columnsRule, columnsRuleChild, columnsRuleChildInstrument, columnsRuleCode, columnsRuleCodeText } from "constant"
import { CheckEmptyAllValueObject, CheckExistObjectInArray, compareSortArray, selectCheckboxHandler } from "helpers/utilities"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getCodesWithPage, getFullTestRuleSuccess, getMachineCodeMapping, getMachines } from "store/actions"
import {
  deleteRuleCustom,
  getAdditionalInfo, getFullTestRule, getResultTime, getRuleCustom, getRuleInstrumentAlert,
  getRuleInstrumentRange, getRuleNormalRange, getRulePatientRange, getRuleResultTime, getRuleRulerBase, getWorkflowCustom,
  importRuleManagement, resetSearchQuery, updateAdditionalInfo, updateResultTime, updateRuleManagement
} from "store/laboratory/ruleManagement/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"
import RuleTestModal from "./Modal/RuleTestModal"
import RuleManagementTable from "./RuleManagementTable"
import TreeViewBar_RulerManager from "./TreeViewBar"

const RESOURCE = ModuleIds.RuleManagement

let itemActiveGlobal = {}
const RuleManagements = ({
  tests,
  onGetTests,
  onGetTestsSuccess,
  onResetTestSearchQuery,
  onGetRuleNormalRange,
  onGetRulePatientRange,
  onGetRuleInstrumentRange,
  paging,
  t,
  onUpdateRuleManagement,
  updatedTime,
  machines,
  onGetMachines,
  onGetMappingCodes,
  onImportRuleManagement
}) => {
  const ref = useRef()
  const [row, setRow] = useState([])
  const [rows, setRows] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [warningModalEmpty, setWarningModalEmpty] = useState(false)
  const [warningDuplicate, setWarningDuplicate] = useState(false)
  const [duplicateRule, setDuplicateRule] = useState([])
  const [warningValueEmpty, setWarningValueEmpty] = useState(false)
  const [valueEmpty, setValueEmpty] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [sapXep, setSapXep] = useState({})
  const [customRule, setCustomRule] = useState({})
  const [openRuleTest, setOpenRuleTest] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    onGetMachines({ status: 'Active', size: 0 })
  }, [])

  useEffect(() => {
    if (tests) {
      tests.sort((a, b) => {
        return b?.children?.length || b?.childrenInstrument?.length > 0 ? 1 : -1;
      })
      if (itemActive.Key == 2) {
        setDataSource(ConvertDataToChild(tests))
      }
      else if (itemActive.Key === 3) {
        setDataSource(ConvertDataToChildInstrument(tests))
      }
      else {
        setDataSource(ConvertDataToChildBasic(tests))
      }
    }
  }, [tests])

  useEffect(() => {
    if (itemActive.Key == 2) {
      setDataExport(ConvertDataToParent(dataSource))
    } else if (itemActive.Key == 3) {
      setDataExport(ConvertDataToParentInstrument(dataSource))
    }
    else {
      setDataExport(ConvertDataToNormalRange(dataSource))
    }
  }, [dataSource])

  const ConvertDataToNormalRange = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index]
      res.push({
        "id": row.id,
        "testCode": row.testCode,
        "testName": row.testName,
        "selected": row.selected,
        "unit": row.unit,
        "normalRange": row.normalRange,
        "lowerLimit": row.lowerLimit,
        "upperLimit": row.upperLimit,
        "lowerAlert": row.lowerAlert,
        "upperAlert": row.upperAlert,
        "enable": !!row.enable
      })
    }
    return res;
  }

  const ConvertDataToParent = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index]
      res.push({
        "id": row.id,
        "selected": row.selected,
        "testCode": row.testCode,
        "testName": row.testName,
        "age": '',
        "ageType": '',
        "gender": '',
        "unit": row.unit,
        "normalRange": row.normalRange,
        "lowerLimit": row.lowerLimit,
        "upperLimit": row.upperLimit,
        "lowerAlert": row.lowerAlert,
        "upperAlert": row.upperAlert,
        "enable": !!row.enable,
      })

      for (let indexChild = 0; indexChild < row?.children?.length; indexChild++) {
        const element = row.children[indexChild];
        res.push({
          "id": row.id,
          "selected": row.selected,
          "testCode": row.testCode,
          "testName": row.testName,
          "age": element.age,
          "ageType": element.ageType,
          "gender": element.gender,
          "unit": element.unit || row.unit,
          "normalRange": element.normalRange || row.normalRange,
          "lowerLimit": element.lowerLimit || row.lowerLimit,
          "upperLimit": element.upperLimit || row.upperLimit,
          "lowerAlert": element.lowerAlert || row.lowerAlert,
          "upperAlert": element.upperAlert || row.upperAlert,
          "enable": !!element.enable || !!row.enable,
        })
      }
    }
    return res;
  }

  const ConvertDataToParentInstrument = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenInstrument && row.childrenInstrument.length > 0) {
        for (let indexChild = 0; indexChild < row.childrenInstrument.length; indexChild++) {
          const element = row.childrenInstrument[indexChild];
          res.push({
            "id": row.id,
            "selected": row.selected,
            "testCode": row.testCode,
            "testName": row.testName,
            "age": element.age,
            "ageType": element.ageType,
            "gender": element.gender,
            "unit": row.unit,
            "normalRange": element.normalRange || row.normalRange,
            "lowerLimit": element.lowerLimit || row.lowerLimit,
            "upperLimit": element.upperLimit || row.upperLimit,
            "lowerAlert": element.lowerAlert || row.lowerAlert,
            "upperAlert": element.upperAlert || row.upperAlert,
            "enable": !!element.enable || !!row.enable,
          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
    }
    return res;
  }
  const ConvertDataToChildBasic = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind < 0) {
        res.push({
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "children": row.children || [],
          "childrenInstrument": row.childrenInstrument || []
        })
      }
    }
    return res;
  }

  const ConvertDataToChild = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind >= 0) {
        res[ind].children.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "unit": row.unit,
          "normalRange": row.normalRange,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
      else {
        const child = {
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "testCode": row.testCode,
          "testName": row.testName,
          "selected": row.selected,
          "unit": row.unit,
          "normalRange": row.normalRange,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "children": row.children || [],
        }
        if (check == false)
          val.children.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "unit": row.unit,
            "normalRange": row.normalRange,
            "lowerLimit": row.lowerLimit,
            "upperLimit": row.upperLimit,
            "lowerAlert": row.lowerAlert,
            "upperAlert": row.upperAlert,
            "enable": !!row.enable,
            "age": row.age,
            "ageType": row.ageType,
            "gender": row.gender,
          })
        res.push(val)
      }
    }
    return res;
  }

  const ConvertDataToChildInstrument = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.childrenInstrument?.length > 0) {
        row.childrenInstrument.forEach((instrument) => {
          instrument.parentId = row.id
        })
      }
      const ind = res.findIndex(x => x.testCode == row.testCode)
      if (ind >= 0) {

        res[ind].childrenInstrument.push({
          "id": new Date().getTime(),
          "parentId": res[ind].parentId,
          "instrument": itemActive.Name,
          "unit": row.unit,
          "normalRange": row.normalRange,
          "lowerLimit": row.lowerLimit,
          "upperLimit": row.upperLimit,
          "lowerAlert": row.lowerAlert,
          "upperAlert": row.upperAlert,
          "enable": !!row.enable,
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        })
      }
      else {
        const child = {
          "age": row.age,
          "ageType": row.ageType,
          "gender": row.gender,
        }
        let check = CheckEmptyAllValueObject(child)
        let val = {
          "id": row.id,
          "instrument": itemActive.Name,
          "testCode": row.testCode,
          "unit": row.unit,
          "testName": row.testName,
          "selected": row.selected,
          "childrenInstrument": row.childrenInstrument || [],
        }
        if (check == false) {
          val.childrenInstrument.push({
            "id": new Date().getTime(),
            "parentId": row.id,
            "instrument": row.instrument,
            "unit": row.unit,
            "normalRange": row.normalRange,
            "lowerLimit": row.lowerLimit,
            "upperLimit": row.upperLimit,
            "lowerAlert": row.lowerAlert,
            "upperAlert": row.upperAlert,
            "enable": !!row.enable,
            "age": row.age,
            "ageType": row.ageType,
            "gender": row.gender,
          })
        }
        res.push(val)
      }
    }

    return res;

  }

  const fetchTests = () => {
    if (itemActive.Key == 3) {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { search, inUse: true } }, () => {
        onGetRuleInstrumentRange({ search, type: NornalRuleType.INSTRUMENT, instrumentId: itemActive.instrumentId })
      })
    } else if (itemActive.Key == 1) {
      onGetTests({ search, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange({ search, type: NornalRuleType.NORMAL })
      })
    }
    else if (itemActive.Key == 2) {
      onGetTests({ search, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange({ search, type: NornalRuleType.NORMAL }, () => {
          onGetRulePatientRange({ search, type: NornalRuleType.PATIENT })
        })
      })
    }
  }

  const onSearch = searchText => {
    setSearch(searchText || '')
    if (itemActive.Key == 3) {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { search: searchText, inUse: true } }, () => {
        onGetRuleInstrumentRange({ search, type: NornalRuleType.INSTRUMENT, instrumentId: itemActive.instrumentId })
      })
    }
    else {
      onGetTests({ search: searchText, isSearch: true, InUse: true, sort: 'DisplayOrder' }, () => {
        if (itemActive.Key == 2) {
          onGetRuleNormalRange(({ search, type: NornalRuleType.NORMAL }), () => {
            if (itemActive.Key == 2) {
              onGetRulePatientRange({ search, type: NornalRuleType.PATIENT })
            }
          })
        }
        else {
          onGetRuleNormalRange({ search, type: NornalRuleType.NORMAL })
        }
      })
    }
  }

  const onRefreshHandler = () => {
    fetchTests()
  }
  useEffect(() => {
    onResetTestSearchQuery()
  }, [])

  const onSelectCheckbox = (row, isSelected, isAddRule) => {
    if (isAddRule) {
      setRows((currentData) => [...currentData, row])
      setRow(row)
    } else {
      const { rowsState, currentRow } = selectCheckboxHandler(
        rows,
        row,
        isSelected
      )
      setRows(rowsState)
      setRow(currentRow)
    }
  }

  const onSelectAllCheckbox = (rows, isSelected) => {
    if (isSelected !== undefined) {
      if (rows.length < 1) {
        setRow({})
        setRows([])
      }
      else {
        setRows(dataSource)
        setRow(dataSource[dataSource.length - 1])
      }
    }
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.selected == 1) {
              rowChoose.push(item)
              const ind = tmp.findIndex(x => x.testCode == item.testCode)
              if (itemActive.Key === 1 || itemActive.Key === 2 || itemActive.Key === 3)
                item.id = tmp[ind]?.id
              if (ind >= 0) {
                if (rowChecked.findIndex(a => a.testCode == item.testCode) >= 0) {
                  //đã thêm testcode này
                  if (itemActiveGlobal.Key == 2) {
                    let d = {
                      id: new Date().getTime(),
                      testCode: item.testCode,
                      unit: item[columnsRuleCodeText['unit']] || '',
                      normalRange: item[columnsRuleCodeText['normalRange']] || '',
                      lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                      upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                      lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                      upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                      enable: !!item[columnsRuleCodeText['enable']],
                      age: item[columnsRuleCodeText['age']] || '',
                      ageType: item[columnsRuleCodeText['ageType']] || '',
                      gender: item[columnsRuleCodeText['gender']] || '',
                      parentId: tmp[ind].id
                    }
                    if (d.age != '' || d.gender != '') {
                      tmp[ind].children.push(d)
                    }
                  }
                  else if (itemActiveGlobal.Key == 3) {
                    let d = {
                      id: new Date().getTime(),
                      testCode: item.testCode,
                      unit: item[columnsRuleCodeText['unit']] || '',
                      normalRange: item[columnsRuleCodeText['normalRange']] || '',
                      lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                      upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                      lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                      upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                      enable: !!item[columnsRuleCodeText['enable']],
                      age: item[columnsRuleCodeText['age']] || '',
                      ageType: item[columnsRuleCodeText['ageType']] || '',
                      gender: item[columnsRuleCodeText['gender']] || '',
                      parentId: tmp[ind].id
                    }
                    if (d.age != '' || d.gender != '') {
                      tmp[ind].children && tmp[ind].children.push(d)
                      tmp[ind].childrenInstrument && tmp[ind].childrenInstrument.push(d)
                    }
                  }
                }
                else {
                  //chưa có testcode này
                  rowChecked.push(item)
                  //check rule status
                  if (itemActiveGlobal.Key == 2) {
                    tmp[ind] = {
                      ...tmp[ind],
                      unit: item.unit || '',
                      normalRange: item.normalRange || '',
                      lowerLimit: item.lowerLimit || '',
                      upperLimit: item.upperLimit || '',
                      lowerAlert: item.lowerAlert || '',
                      upperAlert: item.upperAlert || '',
                      enable: !!item.enable,
                      children: []
                    }
                  }
                  else if (itemActiveGlobal.Key == 3) {
                    let d = {
                      id: new Date().getTime(),
                      testCode: item.testCode,
                      unit: item[columnsRuleCodeText['unit']] || '',
                      normalRange: item[columnsRuleCodeText['normalRange']] || '',
                      lowerLimit: `${item[columnsRuleCodeText['lowerLimit']]}` || '',
                      upperLimit: `${item[columnsRuleCodeText['upperLimit']]}` || '',
                      lowerAlert: `${item[columnsRuleCodeText['lowerAlert']]}` || '',
                      upperAlert: `${item[columnsRuleCodeText['upperAlert']]}` || '',
                      enable: !!item[columnsRuleCodeText['enable']],
                      age: item[columnsRuleCodeText['age']] || '',
                      ageType: item[columnsRuleCodeText['ageType']] || '',
                      gender: item[columnsRuleCodeText['gender']] || '',
                      parentId: tmp[ind].id
                    }
                    if (d.age != '' || d.gender != '') {
                      tmp[ind].childrenInstrument = [d]
                    }
                    else {
                      tmp[ind].childrenInstrument = []
                    }

                  }
                  else {
                    for (const [key, value] of Object.entries(tmp[ind])) {
                      //tmp[ind].unit=item
                      tmp[ind][key] = `${item[columnsRuleCodeText[key]]}`
                    }
                  }
                }
              }
            }
          })
          onGetTestsSuccess(tmp)
          setRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }
  const onSortHandler = (a, b) => {
    const filter = { order: b, dataField: a }
    if (
      sapXep.dataField === filter.dataField &&
      filter.order === sapXep.order
    )
      return;

    const newData = JSON.parse(JSON.stringify(dataSource))
      .sort((a, b) => {
        return compareSortArray(a[filter.dataField], b[filter.dataField], filter.order)
      })
      .map((_item, _idx) => {
        _item.displayOrder = _idx
        return _item
      })
    setSapXep(filter)
    setDataSource(newData)
  }
  const [itemActive, setItemActive] = useState({
    Key: 2, Name: 'Normal Range', parent: { ID_Group: '1', GroupName: t("common:Rule management") }
  })
  const getColumnExport = () => {
    if (itemActive.Key == 2)
      return columnsRuleChild
    else if (itemActive.Key == 3)
      return columnsRuleChildInstrument
    else return columnsRule
  }

  const getDataExport = () => {
    if (itemActive.Key == 2) {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChild.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    } else if (itemActive.Key == 3) {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRuleChildInstrument.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    }
    else {
      let dt = dataExport.map(row => {
        let res = {}
        columnsRule.forEach(element => {
          res[element] = row[columnsRuleCode[element]]
        });
        return res
      })
      return dt
    }
  }

  useEffect(() => {
    if (itemActive.Key === 3) {
      onGetMappingCodes({ instrumentId: itemActive.machineId, query: { inUse: true } }, () => {
        onGetRuleInstrumentRange({ search, type: NornalRuleType.INSTRUMENT, instrumentId: itemActive.instrumentId })
      })
    }
    if (itemActive.Key === 2) {
      onGetTests({ search: null, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange({ search, type: NornalRuleType.NORMAL }, () => {
          onGetRulePatientRange({ search, type: NornalRuleType.PATIENT })
        })
      })
    }
    if (itemActive.Key === 1) {
      onGetTests({ search: null, InUse: true, sort: 'DisplayOrder' }, () => {
        onGetRuleNormalRange({ search, type: NornalRuleType.NORMAL })
      })
    }
    setRows([])
    itemActiveGlobal = itemActive
  }, [itemActive])

  const updateDataExport = (data) => {
    const vals = rows.length > 0 ? (data || []).filter(v => rows.findIndex(f => f.id == v.id) >= 0) : dataSource
    if (itemActive.Key == 2) {
      setDataExport(ConvertDataToParent(vals))
    } else if (itemActive.Key == 3) {
      setDataExport(ConvertDataToParentInstrument(vals))
    }
    else {
      setDataExport(ConvertDataToNormalRange(vals))
    }
  }

  useEffect(() => {
    updateDataExport(dataSource)
  }, [rows])

  const checkDuplicateRule = (data) => {
    let ids = data.map(item => `${item.age}-${item.ageType}-${item.gender}-${item.testCode}`)
    ids = ids.filter((item, index) => item.age !== undefined || item.gender !== undefined)
    const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index && (item.age !== undefined || item.gender !== undefined))
    if (duplicates.length > 0) {
      setDuplicateRule(duplicates)
      setWarningDuplicate(true)
      return true
    }
    return false
  }

  const checkEmptyRule = (data) => {
    const ruleEmpty = data.filter(item => !item?.isParent && (!(item?.age && item?.ageType) && !item?.gender) && (item?.age !== undefined || item?.gender !== undefined))
    if (ruleEmpty.length > 0) {
      const name = ruleEmpty.map(item => `${item.testCode}`)
      setValueEmpty(name)
      setWarningValueEmpty(true)
      return true
    }
    return false
  }

  const submitUpdate = () => {
    let dt = getDataSunmit()
    if (itemActive.Key === 2 || itemActive.Key === 3) {
      if (checkEmptyRule(dt.rules)) {
        return
      }

      if (checkDuplicateRule(dt.rules)) {
        return
      }
    }

    let type = RuleManagementType.normal
    if (itemActive.Key === 2) {
      type = RuleManagementType.patient
    }
    else if (itemActive.Key === 3) {
      type = RuleManagementType.instrument
    }
    if (dt.rules && dt.rules.length === 0) {
      setConfirmModal(false)
      setWarningModalEmpty(true)
      return
    }

    try {
      onUpdateRuleManagement({
        rules: dt, type, machineId: itemActive.instrumentId, callback: () => {
          setConfirmModal(false)
          setFileName(t("common:Upload"))
          fetchTests()
          document.getElementById("importExcel").value = ""
        }
      })
    } catch (error) {
      setConfirmModal(false)
    }
    setRows([])
  }

  const getDataSunmit = () => {
    let tmp = dataSource
    tmp = tmp.filter(x => rows.findIndex(a => a.id == x.id) >= 0)
    let res = []
    if (itemActive.Key == 2) {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index]
        res.push({
          ...element,
          "normalRange": element.normalRange || '',
          "lowerLimit": element.lowerLimit || '',
          "upperLimit": element.upperLimit || '',
          "lowerAlert": element.lowerAlert || '',
          "upperAlert": element.upperAlert || '',
          "enable": !!element.enable,
          isParent: true
        })

        if (element.children && element.children.length > 0) {
          for (let indexChild = 0; indexChild < element.children.length; indexChild++) {
            const x = element.children[indexChild]
            if (x.testCode && x.testCode != "") {
              let newVal = {
                "unit": x.unit || '',
                "normalRange": x.normalRange || '',
                "lowerLimit": x.lowerLimit || '',
                "upperLimit": x.upperLimit || '',
                "lowerAlert": x.lowerAlert || '',
                "upperAlert": x.upperAlert || '',
                "enable": !!x.enable,
                "age": x.age || '',
                "gender": x.gender || '',
                "ageType": x.ageType || '',
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.testCode = x.testCode
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        }
      }
    } else if (itemActive.Key == 3) {
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        if (element.childrenInstrument && element.childrenInstrument.length > 0) {
          for (let indexChild = 0; indexChild < element.childrenInstrument.length; indexChild++) {
            const x = element.childrenInstrument[indexChild]
            if (x.testCode && x.testCode != "") {
              const newVal = {
                "unit": x.unit || '',
                "normalRange": x.normalRange || '',
                "lowerLimit": x.lowerLimit || '',
                "upperLimit": x.upperLimit || '',
                "lowerAlert": x.lowerAlert || '',
                "upperAlert": x.upperAlert || '',
                "enable": !!x.enable,
                "instrumentId": itemActive.instrumentId || null,
                "age": x.age || '',
                "gender": x.gender || '',
                "ageType": x.ageType || '',
              }
              if (!CheckEmptyAllValueObject(newVal)) {
                newVal.testCode = x.testCode
                if (!CheckExistObjectInArray(newVal, res)) {
                  res.push(newVal)
                }
              }
            }
          }
        } else {
          res.push({ testCode: element.testCode, isEmpty: true, instrumentId: itemActive.instrumentId })
        }
      }
    }
    else {
      res = tmp.map(x => ({
        testCode: x.testCode,
        "unit": x.unit || '',
        "normalRange": x.normalRange || '',
        "lowerLimit": x.lowerLimit || '',
        "upperLimit": x.upperLimit || '',
        "lowerAlert": x.lowerAlert || '',
        "upperAlert": x.upperAlert || '',
        "enable": !!x.enable
      }))
    }
    return { rules: res }
  }

  const getTestName = (data) => {
    return data.map(item => {
      const value = item.split('-')
      return value[value.length - 1]
    })
  }

  const GetTypeTestRule = () => {
    if (itemActive.Key == 1) {
      return RuleTestType.NORMAL_RANGE
    } else if (itemActive.Key == 2) {
      return RuleTestType.PATIENT_RANGE
    } else if (itemActive.Key == 3) {
      return RuleTestType.INSTRUMENT_RANGE
    }
    else {
      return "";
    }
  }

  return (
    <React.Fragment>

      <div className="row" style={{ height: "100%", paddingTop: 5 }}>
        <CustomSplitPane
          LeftFrame={() => (
            <TreeViewBar_RulerManager
              onChange={val => {
                setItemActive(val)
                setFileName(t("common:Upload"))
                document.getElementById("importExcel").value = ""
                setSearch('')
                document.getElementById("importExcel").value = ""
              }}
              machines={machines}
            />
          )}
          RightFrame={() => (
            <>
              <div className="rule-management">
                <TitleAndTable
                  table={() => (
                    <span></span>
                  )}
                  resource={RESOURCE}
                  icon
                  isDisableClone
                  isHideEdit
                  isDropDown
                  buttons={() => (
                    <>
                      <CustomButton style={{ width: '130px' }} color="primary" outline
                        onClick={() => { setOpenRuleTest(true) }}>
                        {t('common:Rule Test')}
                      </CustomButton>
                      <CustomButton color="success"
                        onClick={() => {
                          if (rows.length == 0) {
                            setWarningModal(true)
                            return;
                          }
                          setConfirmModal(true)
                        }}
                      >{t('common:Save Config')}
                      </CustomButton>
                      <input
                        id="importExcel"
                        name="reportTemplateName"
                        type="file"
                        className=" d-none"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={e => {
                          setFileName(e.target.files[0]?.name || t("common:Upload"))
                          handleImport(e)
                        }}
                      />
                      <CustomButton
                        isEdit
                        color="primary"
                        outline
                        onClick={() => {
                          $(`#importExcel`).click()
                          setIsUpload(true)
                        }}
                      >
                        {fileName}
                      </CustomButton>
                      <ButtonExport
                        rows={dataSource}
                        data={getDataExport()}
                        itemActive={itemActive}
                        columns={getColumnExport()}
                      />
                    </>
                  )}
                  title="Workflow"
                  subtitle={itemActive.Name}
                />
              </div >
              <React.Fragment>
                <RuleManagementTable
                  updatedTime={updatedTime}
                  itemActive={itemActive}
                  onSelect={onSelectCheckbox}
                  data={dataSource}
                  rows={rows}
                  row={row}
                  search={search}
                  onSelectAll={onSelectAllCheckbox}
                  onSearch={onSearch}
                  onRefresh={onRefreshHandler}
                  paging={paging}
                  ref={ref}
                  onChangeData={(datas) => {
                    updateDataExport(datas)
                  }}
                  onSort={(a, b) => {
                    onSortHandler(a, b);
                  }}
                />
              </React.Fragment >
            </>
          )}
          InitialSize={82}
        />
      </div>

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <WarningModal
        modal={warningModalEmpty}
        onToggle={() => setWarningModalEmpty(prev => !prev)}
        message={t("message:EmptyFormMessage")}
      />
      <WarningModal
        modal={warningValueEmpty}
        onToggle={() => setWarningValueEmpty(prev => !prev)}
        message={`${t('testConfig:Empty Rule')}: ${getTestName(valueEmpty).join(',')}`}
      />
      <WarningModal
        modal={warningDuplicate}
        onToggle={() => setWarningDuplicate(prev => !prev)}
        message={`${t('testConfig:Duplicate Rule')}: ${getTestName(duplicateRule).join(',')}`}
      />
      <ConfirmModal2
        modal={confirmModal}
        title={t("common:Config")}
        message={t("common:Save Config?")}
        onToggle={() => { setConfirmModal(prev => !prev) }}
        onConfirm={submitUpdate}
        btnConfirmText={t("common:Save")}
        isEdit
      />
      <RuleTestModal
        modal={openRuleTest}
        toggle={() => { setOpenRuleTest(prev => !prev) }}
        type={GetTypeTestRule()}
        itemActive={itemActive}
        workflowId={customRule.workflowId}
      />
    </React.Fragment >
  )
}

const mapStateToProps = ({ RuleManagement, machine }) => ({
  machines: machine.machines,
  tests: RuleManagement.tests,
  paging: RuleManagement.paging,
  updatedTime: RuleManagement.updatedTime
})

const mapDispatchToProps = dispatch => ({
  onGetMachines: payload => dispatch(getMachines(payload)),
  onGetMappingCodes: (payload, callback) => dispatch(getMachineCodeMapping(payload, callback)),
  onGetTests: (payload, callback) => dispatch(getFullTestRule(payload, callback)),
  onGetRuleNormalRange: (payload, callback) => dispatch(getRuleNormalRange(payload, callback)),
  onGetRulePatientRange: (payload, callback) => dispatch(getRulePatientRange(payload, callback)),
  onGetRuleInstrumentRange: (payload, callback) => dispatch(getRuleInstrumentRange(payload, callback)),
  onGetRuleInstrumentAlert: (payload, callback) => dispatch(getRuleInstrumentAlert(payload, callback)),
  onGetRuleRulerBase: (payload, callback) => dispatch(getRuleRulerBase(payload, callback)),
  onUpdateRuleManagement: (payload) => dispatch(updateRuleManagement(payload)),
  onGetTestsSuccess: payload => dispatch(getFullTestRuleSuccess(payload)),
  onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
  getCustomWorkflowList: payload => dispatch(getWorkflowCustom(payload)),
  onGetRules: payload => dispatch(getRuleCustom(payload)),
  onDeleteCustomRule: (payload, callback) => dispatch(deleteRuleCustom(payload, callback)),
  onGetCodes: (paramId, sort, search, inUse, callback) => dispatch(getCodesWithPage({ paramId, sort, search, inUse, callback })),
  onGetRuleResultTime: (payload, callback) => dispatch(getRuleResultTime(payload, callback)),
  onGetResultTime: (payload, callback) => dispatch(getResultTime(payload, callback)),
  onUpdateResultTime: (payload, callback) => dispatch(updateResultTime(payload, callback)),
  onGetAdditionalInfo: (payload, callback) => dispatch(getAdditionalInfo(payload, callback)),
  onUpdateAdditionalInfo: (payload, callback) => dispatch(updateAdditionalInfo(payload, callback)),
  onImportRuleManagement: (payload, callback) => dispatch(importRuleManagement(payload, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["message, common, testConfig"])(RuleManagements)))