import { CustomSID } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { convertDateFormat_VN, indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const VissitedPatient = ({ data, t, onResetHandler }) => {
  const columns = [
    {
      dataField: "id",
      text: "No.",
      style: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, item, index) => {
        if (item.id == -1) {
          return <span id={`watch_end_of_document3`}></span>
        }
        else
          return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "alternateId",
      text: "Ordercode",
      formatter: (cellContent, item, index) => {
        return item.alternateId
      },
    },
    {
      dataField: "patientId",
      text: "SID",
      formatter: (cellContent, item, index) => {
        return item.sampleIds && <CustomSID
        key={`${index}-visited`}
        keyToolTip={`${index}-visited`}
        isToolTip={true}
        toolTip={JSON.parse(item.sampleIds)[0]?.SampleTypeName}
        sid={item.sampleIds && JSON.parse(item.sampleIds)[0]?.SID}
        emergency={item?.emergency}
      />
      
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex">
            {item?.groupCode && <i
              key={index}
              data-tip
              data-for={`groupCode-${index}`}
              className="mdi mdi-bookmark"
              style={{
                fontSize: 'larger',
                color: 'purple'
              }}
            >
              {item.organizationName && <ReactTooltip id={`groupCode-${index}`} place="top" effect="solid">
                {item.organizationName || ''}
              </ReactTooltip>}
            </i>}
          </div>
        )
      }
    },
    {
      dataField: "patientName",
      text: t("Name"),
      formatter: (cellContent, item, index) => {
        return (
          <div style={{ color: "#3c3ce4", paddingTop: '2px' }}>{item.patientName}</div>
        )
      }
    },
    {
      dataField: "age",
      text: t("SN"),
      formatter: (cellContent, item, index) => item.dob && <div>{convertDateFormat_VN(item.dob, "DD-MM-YYYY")}</div>
    }
  ]
  return (
    <div className={`table-patient-visit ${data.length > 0 ? 'table-expand-custom table-rule-management' : ''}  `}>
      <CustomBootstrapTableAsync
        columns={columns}
        data={data}
        keyField="id"
        onSizePerPageChange={() => { }}
        showSelectRow={false}
        style={{ height: '270px' }}
        onRefresh={onResetHandler}
      />
    </div>
  )
}
export default withTranslation(["testRequestPage", "message", "patientVisitPage"])(VissitedPatient)
