import {
    Check,
    CustomButton
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"
const HeaderButtons = ({
    resource,
    t,
    onClick,
    disabled,
    onValid,
    onInValid,
    onRefresh,
    loadingRefresh = false,
    rows
}) => {


    const isContaintValid = rows.find(item => item.state >= 90)
    const isContaintInValid = rows.find(item => item.state < 90)

    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <button disabled={disabled} className="btn btn-light"
                    onClick={() => { onRefresh() }}>
                    <i className={`fas fa-sync-alt ${loadingRefresh ? 'loading-spin' : ''}`}></i>
                </button>
            </Check>
            <Check permission={permissionType.U} resource={resource}>
                <button
                    disabled={disabled || !!isContaintValid} className="btn btn-light"
                    onClick={() => {
                        onValid()
                    }}>
                    <span style={{ marginRight: 10 }}>
                        <i className="fas fa-check"></i>
                    </span>
                    {t("Valid Result")}
                </button>
                <button
                    disabled={disabled || !!isContaintInValid} className="btn btn-light"
                    onClick={() => {
                        onInValid()
                    }}>
                    <span style={{ marginRight: 10 }}>
                        <i className="fas fa-info-circle"></i>
                    </span>
                    {t("InValid Result")}
                </button>
                <button
                    disabled={disabled || !!isContaintValid} className="btn btn-light"
                    onClick={() => {
                        onClick()
                    }}>
                    <span style={{ marginRight: 10 }}>
                        <i className="fas fa-pen"></i>
                    </span>
                    {t("Modify Result")}
                </button>
            </Check>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
}

export default withTranslation(["workListPage"])(HeaderButtons)
