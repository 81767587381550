import React, { useEffect, useState } from "react"

import { Check, CustomBootstrapTable } from "components/Common"

//i18n
import IconStatus from "components/Common/IconStatus"
import CustomSIDHover from "components/Common/Input/CustomSIDHover"
import { permissionType, ReportResource, RESULT_STATE, RESULT_STATE_Text, statusTypes } from "constant"
import { getFilePrintResult } from "helpers/app-backend"
import { GetDataUrlReportConfig, indexCalculator } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
import { UncontrolledTooltip } from "reactstrap"

const VisitHistoryTable = ({
  onSelect,
  onSelectAll,
  visits,
  onSort,
  onPageChange,
  paging,
  onSizePerPageChange,
  loading,
  t,
  updatedTime,
  onRefresh,
  resource,
  onSubmitFilter,
  onSearch,
  model,
  onResetHandler,
  onChangeModel
}) => {
  const [data, setData] = useState([])
  const { reportInfos } = useSelector(state => ({
    reportInfos: state.Authorization.reportinfo || [],
  }))
  useEffect(() => {
    setData(visits)
  }, [visits])

  const PrintConfig = async (id) => {
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrintResult({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.TestResult, ""),
        ResultId: `${id}`,
      })
      window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "sid",
      text: t("Request Date"),
      formatter: (cellContent, row, index) => {
        return <Link to={`/TestRequest/${row.requestId}/view?tab=1`}>{cellContent}</Link>
      },
    },
    {
      dataField: "SampleIds",
      text: t("SampleId"),
      formatter: (cellContent, row, indexParent) => {
        return <CustomSIDHover data={row.sampleIds} indexParent={indexParent} />
      },
    },
    {
      dataField: "age",
      text: t("common:Age"),
      formatter: (cellContent, row, index) => (
        <span>{cellContent < 0 ? '' : cellContent}</span>
      ),
    },
    {
      dataField: "genderName",
      text: t("common:Gender"),
    },
    {
      dataField: "physicianName",
      text: t("Physician"),
      formatter: (cellContent, testRequest, index) => {
        return <Link to={`/Physician/${testRequest.physicianId}/view?tab=1`} target="_blank">{cellContent}</Link>
      },
    },
    {
      dataField: "companyName",
      text: t("common:Company"),
      formatter: (cellContent, testRequest, index) => {
        return <Link to={`/company/${testRequest.companyId}/view?tab=1`} target="_blank">{cellContent}</Link>
      },
    },
    {
      dataField: "departmentName",
      text: t("common:Department"),
      formatter: (cellContent, testRequest, index) => {
        return <Link to={`/department/${testRequest.departmentId}/view?tab=1`} target="_blank">{cellContent}</Link>
      },
    },
    {
      dataField: "diagnosisName",
      text: t("common:Diagnosis"),
    },
    {
      dataField: "state",
      text: t("common:Status"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, row, index) => {
        return (
          <React.Fragment>
            <div className="tooltip-custom">
              <IconStatus
                id={"UncontrolledTooltipStateComplete" + index}
                isRound={false}
                type={cellContent >= RESULT_STATE_Text.Completed ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                icon={'check-square'}
                color={cellContent >= RESULT_STATE_Text.Completed ? '#28a745' : 'rgba(0,0,0,0.8)'}
                className="margin-right-5 margin-left-5"
              />
              <span className="tooltiptext-custom">
                {cellContent >= RESULT_STATE_Text.Completed ? t("Đã đủ kết quả") : t("Chưa đủ kết quả")}
              </span>
            </div>
            <div className="tooltip-custom">
              <IconStatus
                id={"UncontrolledTooltipStateConfirmed" + index}
                isRound={true}
                type={cellContent >= RESULT_STATE_Text.Confirmed ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                icon={'check'}
                color={cellContent >= RESULT_STATE_Text.Confirmed ? '#007bff' : 'gray'}
                className="margin-right-5 margin-left-5"
              />
              <span className="tooltiptext-custom">
                {cellContent >= RESULT_STATE_Text.Confirmed ? t("Đã trả kết quả") : t("Chưa trả kết quả")}
              </span>
            </div>
          </React.Fragment>
        )
      },
    },
    {
      dataField: "createdUserName",
      text: t("Created User"),
      formatter: (cellContent, user, index) => {
        return <Link to={`/User/${user.createdBy}/view?tab=1`}>{cellContent}</Link>
      },
    },
    // {
    //   dataField: "action",
    //   text: "",
    //   isDummyField: true,
    //   style: { width: 50 },
    //   headerStyle: { width: "50px" },
    //   formatter: (cellContent, code, index) => (
    //     <Check resource={resource} permission={permissionType.U}>
    //       <span onClick={(e) => {
    //         onEdit(e, code)
    //       }}>
    //         <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
    //       </span>
    //     </Check>
    //   ),
    // },
    // {
    //   dataField: "action",
    //   text: "",
    //   isDummyField: true,
    //   headerStyle: { width: "50px" },
    //   style: { width: 50 },
    //   formatter: (cellContent, code, index) => (
    //     <Check resource={resource} permission={permissionType.D}>
    //       <span onClick={(e) => {
    //         onDelete(e, code)
    //       }}>
    //         <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
    //       </span>
    //     </Check>
    //   ),
    // },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSort={onSort}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      loading={loading}
      updatedTime={updatedTime}
      resource={resource}
      showSelectRow={false}
      search
      onRefresh={onRefresh}
      onSubmitFilter={onSubmitFilter}
      onSearch={onSearch}
      searchText={model.search}
      onReset={onResetHandler}
      filterForm={() => <FilterForm
        onChangeModel={onChangeModel}
        model={model}
      />}
    />
  )
}
export default withTranslation(["partyPage", "common"])(VisitHistoryTable)
