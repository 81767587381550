import { AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
//i18n
import { CustomSelect, CustomSelectAsync, DatePicker } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <DatePicker name="start"
                        label={t("Start date")} value={model.start}
                        placeholder={"YYYY-MM-DD"}
                        format={"YYYY-MM-DD"}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <DatePicker name="end"
                        label={t("End date")}
                        value={model.end}
                        placeholder={"YYYY-MM-DD"}
                        format={"YYYY-MM-DD"}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelectAsync
                        name="physicianId"
                        portal
                        value={model.physicianId || ""}
                        code={parameterCode.COMMON_PHYSICIANS}
                        label={t("Physician")}
                        onChange={(name, value, label, item) => {
                            model.physicianId = value[0];
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="departmentId"
                        portal
                        value={model.departmentId || ""}
                        code={parameterCode.DEPARTMENTS}
                        label={t("Department")}
                        onChange={(name, value, label, item) => {
                            model.departmentId = value[0];
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("State")}
                        portal
                        name="state"
                        value={model.state}
                        code={parameterCode.TESTREQUEST_STATE_KEY}
                        onChange={(name, value, label, item) => {
                            model.state = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testRequestPage", "common"])(FilterForm)