
export const GET_WORK_LIST = "GET_WORK_LIST"
export const GET_WORK_LIST_SUCCESS = "GET_WORK_LIST_SUCCESS"
export const GET_WORK_LIST_FAIL = "GET_WORK_LIST_FAIL"

export const UPDATE_WORK_LIST = "UPDATE_WORK_LIST"
export const UPDATE_WORK_LIST_SUCCESS = "UPDATE_WORK_LIST_SUCCESS"
export const UPDATE_WORK_LIST_FAIL = "UPDATE_WORK_LIST_FAIL"

export const VALID_WORK_LIST = "VALID_WORK_LIST"
export const VALID_WORK_LIST_SUCCESS = "VALID_WORK_LIST_SUCCESS"
export const VALID_WORK_LIST_FAIL = "VALID_WORK_LIST_FAIL"

export const INVALID_WORK_LIST = "INVALID_WORK_LIST"
export const INVALID_WORK_LIST_SUCCESS = "INVALID_WORK_LIST_SUCCESS"
export const INVALID_WORK_LIST_FAIL = "INVALID_WORK_LIST_FAIL"

export const GET_WORK_LIST_EXPORT = "GET_WORK_LIST_EXPORT"
export const GET_WORK_LIST_EXPORT_SUCCESS = "GET_WORK_LIST_EXPORT_SUCCESS"
export const GET_WORK_LIST_EXPORT_FAIL = "GET_WORK_LIST_EXPORT_FAIL"