import {
    CustomButton, CustomModal
} from "components/Common";
import { ModalBody, ModalFooter } from "reactstrap";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import { convertDateFormat } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isEmpty } from "lodash";

const HistoryModal = ({
    modal,
    toggle,
    data,
    formEl,
    onValidSubmit,
    t,
    ...rest
}) => {
    const title = <div>
        <span>{t("General Result History")} ({data[0]?.testCode} - {data[0]?.testName})</span>
    </div>

    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        let resultData = data.filter(item => item.state == 8).map(item => ({
            result: item.result,
            resultText: item.resultText,
            machineName: item.machineName,
            normalRange: item.normalRange,
            unit: item.unit,
            updatedUser: item.updatedUser,
            updatedDate: item.updatedDate,
        }))

        resultData = resultData.filter((item, index) => {
            return index === 0 || JSON.stringify({ ...item, updatedDate: '' }) !== JSON.stringify({ ...resultData[index - 1], updatedDate: '' })
        })

        setDataSource(resultData)
    }, [data])

    useEffect(() => {
        if (!modal) {
            setDataSource([])
        }
    }, [modal])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "result",
            text: t("Result"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "resultText",
            text: t("Result Text"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "machineName",
            text: t("Machine Name"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "normalRange",
            text: t("Normal Range"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "unit",
            text: t("Unit"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "updatedUser",
            text: t("common:User"),
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "updatedDate",
            text: t("common:Date"),
            formatter: (cellContent, item, index) => {
                return convertDateFormat(cellContent || item.createdDate, 'YYYY-MM-DD HH:mm:ss')
            },
        },
    ]
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" customClass={"model-height"} >
            <ModalBody>
                <>
                    <CustomBootstrapInlineTable
                        columns={columns}
                        data={dataSource || []}
                        isScrollable
                        isEnableLoadInfo={true}
                        searchText={""}
                        draggable
                        keyField={"id"}
                        {...rest}
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("testResultPage:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

HistoryModal.propTypes = {
}

export default withTranslation(["testResultPage", "common"])(HistoryModal)