import { CustomBootstrapTable, CustomButton, CustomSID } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import { ModuleIds } from "constant"
import { convertDateFormat, indexCalculator, mapStatusToDescription } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.AnalyticalProcess

const AnalysisProcessTable = ({
  onSelect,
  onSelectAll,
  results,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  t,
  updatedTime,
  isSelected,
  onAnalysisProcessExport
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    instrumentId: "",
    requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
    status: "",
    testCode: "",
  })
  const searchQuery = useSelector(state => state.validResult.searchQuery)
  const onResetHandler = () => {
    const initModel = {
      search: "",
      instrumentId: "",
      requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
      status: "",
      testCode: "",
    }
    setModel(initModel)
  }
  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])
  useEffect(() => {
    setData(results)
  }, [results])


  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, test, index) => {
        return cellContent
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, test, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("Request Date"),
      sort: true,
      style: { color: "#556ee6" },
      formatter: (cellContent, test, index) => {
        return (
          <Link
            to={`/TestRequest/${test.requestId}/view?tab=1`}
            target="_blank"
          >
            {convertDateFormat(test.requestDate, "YYYY-MM-DD")}
          </Link>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return (
          <CustomSID
            key={`sampleType-${index}`}
            keyToolTip={`sampleType-${index}`}
            // isToolTip={true}
            // toolTip={item.sampleTypeName}
            sid={item.subSID > 0 ? `${cellContent}-${item.subSID}` : cellContent}
          // emergency={item?.emergency}
          />
        )
      },
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      // formatter: (cellContent, test, index) => {
      //   return (
      //     <Link to={`/Test/${cellContent}/view?tab=1`} target="_blank">
      //       {cellContent}
      //     </Link>
      //   )
      // },
    },
    {
      dataField: "instrumentTestCode",
      text: t("Ins Code"),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      // formatter: (cellContent, test, index) => {
      //   return (
      //     <Link to={`/Test/${test.testCode}/view?tab=1`} target="_blank">
      //       {cellContent}
      //     </Link>
      //   )
      // },
    },
    {
      dataField: "result",
      text: t("Result"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return (
          <b>
            {item.resultText && cellContent
              ? `${item.result}/${item.resultText}`
              : item.resultText
                ? item.resultText
                : cellContent}
          </b>
        )
      },
    },
    {
      dataField: "unit",
      text: t("Unit"),
    },
    {
      dataField: "status",
      text: t("common:Status"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, testResult, index) => {
        return (
          <>
            <BadgeLabel
              value={cellContent}
              type={cellContent}
              className="badge-rewidth"
              dataFor={cellContent}
            />
            <ReactTooltip id={cellContent} place="top" effect="solid">
              <span>{mapStatusToDescription(cellContent)}</span>
            </ReactTooltip>
          </>
        )
      },
    },
    {
      dataField: "instrumentId",
      text: t("Ins Id"),
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, test, index) => {
        return (
          <>
            {test.instrumentName ? (
              <span data-tip data-for={test.instrumentName}>
                {cellContent}
              </span>
            ) : (
              "---"
            )}
            {test.instrumentName && (
              <ReactTooltip id={test.instrumentName} place="top" effect="solid">
                <span>{test.instrumentName}</span>
              </ReactTooltip>
            )}
          </>
        )
      },
    },
    {
      dataField: "runCount",
      text: t("Run"),
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "runTime",
      text: t("Process Time"),
      formatter: (cellContent, item, index) => convertDateFormat(cellContent),
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      isSelected={isSelected}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
      keyField="id"
      isImportExportButton={true}
      importExportForm={() => <div className="tooltip-custom">
        <CustomButton
          isEdit
          color="secondary"
          outline
          onClick={onAnalysisProcessExport}
        >
          <i className={"fas fa-download"}></i>
        </CustomButton>
        <span className="tooltiptext-custom-button-icon-left">
          {t("Export")}
        </span>
      </div>}
    />
  )
}

const mapStateToProps = ({ }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["resultPage", "common"])(AnalysisProcessTable))
