import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import SampleTable from "./SampleTable"

import {
  ConfirmModal2,
  CustomButton,
  CustomSelectAsync,
  TitleAndTable,
  WarningModal
} from "components/Common"
import HeaderButtonsTab from "../TabSample/Header/HeaderButtons"

import {
  convertDateFormat,
  convertStringForSearch,
  selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, SAMPLES_DELIVERY_TYPE, parameterCode } from "constant"
import {
  addDeliverySample,
  deleteDeliverySample,
  getDeliveryAllTests,
  getDeliveryDetail,
  getDeliverySamples,
  searchDeliverySampleDetail,
  updateDeliverySamples,
  updateMassDeliverySamples
} from "store/actions"
import CustomSearchBar from "../TabTest/SearchBar"
import { AvForm } from "availity-reactstrap-validation"

const RESOURCE = ModuleIds.DeliveryReceive

const TabSample = ({
  deliveryId,
  deliveryType,
  samples,
  loadingSamples,
  updatedSampleTime,
  onGetDeliverySamples,
  delivery,
  onSearchDeliverySampleDetail,
  t,
  onDelete,
  onSubmit,
  isCollapse,
  enableBarcode
}) => {
  const [saveConfirmModal, setSaveConfirmModal] = useState(false)
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [model, setModel] = useState({
  })
  const [reason, setReason] = useState({})

  useEffect(() => {
    if (samples) {
      setDataTable(samples)
    } else {
      setRows([])
    }
  }, [samples])

  const onDeleteHandler = (sample) => {
    onDelete(sample)
  }

  const onMultiDeleteHandler = () => {
    if (rows.length === 0) {
      setWarningModal(true)
    } else {
      onDelete(rows)
      setRows([])
    }
  }

  const toggleConfirmSaveSample = () => {
    setSaveConfirmModal(!saveConfirmModal)
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const changeRemarkHandler = (value, sample) => {
    dataTable.forEach((item) => {
      if (item.sampleId === sample.sampleId
        && item.sampleType === sample.sampleType
        && item.patientId === sample.patientId
        && item.subSID === sample.subSID) {
        item.remark = value
      }
    })
  }

  const onChangeQuality = (value, sample) => {
    let newData = [...dataTable]
    newData.forEach((item) => {
      if (item.sampleId === sample.sampleId
        && item.sampleType === sample.sampleType
        && item.patientId === sample.patientId
        && item.subSID === sample.subSID) {
        item.qualityName = value.label
        item.quality = value.code
      }
    })
  }

  const onChangeReason = (value, sample) => {
    dataTable.forEach((item) => {
      if (item.sampleId === sample.sampleId
        && item.sampleType === sample.sampleType
        && item.patientId === sample.patientId
        && item.subSID === sample.subSID) {
        item.reason = value.value
        item.reasonName = value.label
      }
    })
  }

  const onReasonChange = (value) => {
    dataTable.forEach(item => {
      item.reason = value.code
      item.reasonName = value.label
    })
    setDataTable([...dataTable])
    setReason(value)
  }

  const onSaveSample = () => {
    onSubmit(dataTable)
  }

  const onSearchChange = value => {
    model.search = value
  }

  const onSearchClear = () => {
    model.search = ""
    setModel({ ...model })
  }

  const onSearchSubmit = e => {
    e.preventDefault()
    setModel({ ...model })
  }

  let dataFilter = dataTable
  if (model.search) {
    const searchConvert = convertStringForSearch(model.search)
    dataFilter = dataFilter.filter(item => convertStringForSearch(item?.sidName?.toString())?.includes(searchConvert)
      || convertStringForSearch(item?.sampleTypeName).includes(searchConvert)
      || convertStringForSearch(item?.patientName)?.includes(searchConvert)
      || convertStringForSearch(item?.sampleType)?.includes(searchConvert))
  }

  return (
    <React.Fragment>
      <TitleAndTable
        resource={RESOURCE}
        table={() => (
          <SampleTable
            data={dataFilter}
            deliveryType={deliveryType}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteHandler}
            loading={loadingSamples}
            model={model}
            updatedTime={updatedSampleTime}
            onChangeRemark={changeRemarkHandler}
            onChangeQuality={onChangeQuality}
            onChangeReason={onChangeReason}
            delivery={delivery}
            isCollapse={isCollapse}
            onSubmit={(val) => {
              onGetDeliverySamples(deliveryId, () => {
                onSearchDeliverySampleDetail({ searchText: val })
              })
            }}
            customButtonTable={() => (
              <AvForm>
                <div className="d-flex" style={{ width: '800px', justifyContent: 'right' }}>
                  <CustomSearchBar
                    onSearch={onSearchChange}
                    searchText={model.search}
                    placeholder={t("common:Search")}
                    onClear={onSearchClear}
                    onSubmit={onSearchSubmit}
                    disabled={enableBarcode}
                  />
                  {deliveryType !== SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt
                    && deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation
                    && <div style={{ width: "35%" }} className="mx-1">
                      <CustomSelectAsync
                        name="reasonCorrection"
                        value={reason.code}
                        errorMessage={""}
                        isClearable={false}
                        code={parameterCode.REASON_RETURN_SMAPLE_KEY}
                        label={t("")}
                        required
                        placeholder={t("Reason")}
                        onChange={(e, value, label) => {
                          onReasonChange({ code: value[0], label: label })
                        }}
                      />
                    </div>}
                  <CustomButton
                    color="primary"
                    outline
                    onClick={onMultiDeleteHandler}
                    style={{ height: "37px" }}
                  >
                    {t("common:Delete")}
                  </CustomButton>
                  {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation
                    && <CustomButton
                      style={{ height: "37px" }}
                      color="success"
                      onClick={onSaveSample}
                      isEdit
                      disabled={dataTable?.length === 0}
                    >
                      {t("common:Save")}
                    </CustomButton>}
                </div>
              </AvForm>
            )}
          />
        )}
        icon={false}
        external
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal2
        modal={saveConfirmModal}
        title={`${t("common:Save")} ${t("common:Sample")}`}
        message={t("SaveSampleConfirm")}
        onToggle={toggleConfirmSaveSample}
        btnConfirmText={t("common:Save")}
      />
    </React.Fragment>
  )
}

TabSample.propTypes = {
  deliveryId: PropTypes.string.isRequired,
  samples: PropTypes.array,
  loadingSamples: PropTypes.bool,
  updatedSampleTime: PropTypes.any,
  onEditDeliverySamples: PropTypes.func,
  qualityOptions: PropTypes.array,
  t: PropTypes.any,
  onGetDeliveryDetail: PropTypes.func,
}

TabSample.defaultProps = {}

const mapStateToProps = ({ sampleDelivery }) => ({
  requestSamples: sampleDelivery.requestSamples,
  loadingSamples: sampleDelivery.loadingSamples,
  updatedSampleTime: sampleDelivery.updatedSampleTime,
  qualityOptions: sampleDelivery.qualityOptions,
  delivery: sampleDelivery.delivery
})

const mapDispatchToProps = dispatch => ({
  onGetDeliverySamples: (deliveryId, callback) => dispatch(getDeliverySamples(deliveryId, callback)),
  onEditDeliverySamples: (sample, callback) => dispatch(updateDeliverySamples({ sample, callback })),
  onMassEditDeliverySamples: (sample, callback) => dispatch(updateMassDeliverySamples({ sample, callback })),
  onCreateDeliverySample: (sample, callback) => dispatch(addDeliverySample({ sample, callback })),
  onDeleteDeliverySample: (samples, callback) => dispatch(deleteDeliverySample({ samples, callback })),
  onGetDeliveryAllTests: payload => dispatch(getDeliveryAllTests(payload)),
  onSearchDeliverySampleDetail: payload => dispatch(searchDeliverySampleDetail(payload)),
  onGetDeliveryDetail: id => dispatch(getDeliveryDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "message", "common"])(TabSample))
