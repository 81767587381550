import React, { useEffect, useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Check,
  ConfirmModal,
  ConfirmModal2,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import { getMyInfo } from "helpers/common_services_helper"
import {
  GetDataUrlReportConfig,
  GetResourceReportConfig,
  GetResourceReportIdConfig,
  GetResourceReportNameConfig,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import CancelationRequestTable from "./CancelationRequestTable"
import {
  getDeliveries,
  getDeliveryDetail,
  resetDeliveriesSearchQuery,
  addDelivery,
  updateDelivery,
  deleteDeliveries,
  addNewFile,
} from "store/actions"

//i18n
import { withTranslation } from "react-i18next"
import { DeliveryStateText, ExportExtension, ModuleIds, permissionType, ReportAction, ReportResource, SAMPLES_DELIVERY_TYPE, UpdateType } from "constant"
import CancelationRequestModal from "./Modal/CancelationRequestModal"
import { insertUrlParam, appendUrl, getUrlParams, getUrlParamByKey, appendParams } from "helpers/utilities"
import ModalConfigPrinter from "components/Common/Modals/ModalConfigPrinter"
import HeaderButtons from "./HeaderButtons"
import ModalPreview from "components/Common/Modals/ModalPreview"
import { isEmpty } from "lodash"
import { exportFile, getFileById, getFilePrint, sendDataPrint, pingToPrintService, exportDeliveryList } from "helpers/app-backend"

const RESOURCE = ModuleIds.DeliveryManagement

const CancelationRequest = ({
  history,
  deliveries,
  paging,
  onGetDeliveries,
  onAddNewDelivery,
  onUpdateDelivery,
  onDeleteDeliveries,
  onResetDeliveriesSearchQuery,
  onGetDeliveryDetail,
  delivery,
  loadingDeliveries,
  updatedDeliveriesTime,
  onAddNewFile,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false)
  const [modalPreview, setModalPreview] = useState(false);
  const [warningModal, setWarningModal] = useState(false)
  const [warningReport, setWarningReportModal] = useState(false)
  const [disableAction, setDisableAction] = useState(false)
  const [myInfo, setMyInfo] = useState({})
  const [confirmCreateReport, setConfirmCreateReport] = useState(false)

  const formEl = useRef(null)
  const [actionState, setActionState] = useState('');
  const { reportInfos } = useSelector(state => ({
    reportInfos: state.Authorization.reportinfo || [],
  }))

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addDeliveryClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Profile on Profile form
   */
  const handleValidSubmit = (e, values) => {
    values.type = SAMPLES_DELIVERY_TYPE.Cancelation
    if (isEdit) {
      values.relatedIds = values.relatedIds.toString()
      onUpdateDelivery({ delivery: values, callback: toggle })
    } else {
      values.relatedIds = values.relatedIds.toString()
      values.state = DeliveryStateText.OPEN
      onAddNewDelivery({ delivery: values, history, RESOURCE: ModuleIds.CancelationRequest })
    }
  }

  const onCloneHandler = (e, delivery) => {
    if (delivery) {
      setRow(delivery)
      onGetDeliveryDetail(delivery.id)
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      onGetDeliveryDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteDeliveries({
      deliveries: rowsState, callback: () => {
        resetState()
        onRefreshAfterAction()
      }
    })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteDeliveries({
      deliveries: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
        onRefreshAfterAction()
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteDeliveryHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const onGetDeliveryList = (payload) => {
    const param = { ...payload }
    delete param.type
    insertUrlParam(param)
    onGetDeliveries({ ...payload, type: "U" })
  }
  const fetchDeliveries = () => {
    onGetDeliveryList({ page: 1, type: "U" })
  }

  const fetchDeliveriesCurrentPage = () => {
    onGetDeliveryList({ type: "U" })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchDeliveries()
  }

  const onRefreshAfterAction = () => {
    resetState()
    fetchDeliveriesCurrentPage()
  }

  const onSearch = searchText => {
    onGetDeliveryList({ page: 1, search: searchText, type: "U" })
  }

  const onSizePerPageChange = size => {
    onGetDeliveryList({ page: 1, size, type: "U" })
  }

  const onPageChange = page => {
    onGetDeliveryList({ page, type: "U" })
  }

  const onSubmitFilter = values => {
    onGetDeliveryList({ page: 1, ...values, type: "U" })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetDeliveryList({ page: 1, sort: sortString, type: "U" })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetDeliveriesSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetDeliveryList(params)
    } else {
      fetchDeliveries()
    }
  }, [])

  const getMyUserInfo = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo) {
      const { sub } = JSON.parse(userInfo)
      setMyInfo(await getMyInfo(sub))
    }
  }, [])

  useEffect(() => {
    getMyUserInfo()
  }, [])

  const onPrintHandler = (state) => {
    setActionState(state)
    const id = row?.id;
    if (id) {
      PrintConfig()
    } else {
      setWarningModal(true);
    }

  }
  const onPreviewHandler = (state) => {
    setActionState(state)
    const id = row?.id;
    if (id) {
      //if (row.fileId)
      setModalPreview(true);
    } else {
      setWarningModal(true);
    }

  }

  const onExportHandler = async (state) => {
    setActionState(state)
    const id = row?.id;
    if (id) {
      {
        const data = {
          Id: GetResourceReportIdConfig(reportInfos),
          Module: ModuleIds.SampleDelivery,
          ItemAction: ReportAction.Export,
          ItemValue: `${id}`,
          reportParameters: {
            ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, row.fileId),
            "DeliveryId": `${id}`,
            "exportFormat": ExportExtension.pdf,
            page: `${row.type}`
          }
        }
        const res = await exportFile(data)
        const fileData = await getFileById(res.id)
        let extension = ExportExtension.pdf;
        let tempFileName = GetResourceReportNameConfig(reportInfos)
        let fileName = `${tempFileName}.${extension}`;

        const blob = new Blob([fileData], {
          type: 'application/octet-stream'
        })
        saveAs(blob, fileName)
      }
    } else {
      setWarningModal(true);
    }

  }

  const onReportSettingHandler = (state) => {
    const id = row?.id;
    if (id) {
      setDisableAction(true)
    }
    setOpen(true);
  }

  const confirmSuccess = () => {
    const id = row?.id
    onCreateReport(id)
  }
  const toggleConfirmCreateReport = () => {
    setConfirmCreateReport(prev => !prev)
  }
  const confirmCreateReportHandler = async () => {
    if (row.state === DeliveryStateText.CONFIRMED) {
      onCreateReport(row?.id)
    } else {
      onUpdateDelivery({
        delivery: prepareObj2Confirm(),
        callback: confirmSuccess,
      })
    }
  }
  const prepareObj2Confirm = () => {
    const userInfo = localStorage.getItem("userInfo")
    let deliver2Confirm = {}
    if (userInfo) {
      const { sub, family_name, given_name } = JSON.parse(userInfo)
      deliver2Confirm = {
        ...row,
        id: row.id,
        confirmedUserId: sub,
        confirmedUserName: `${family_name} ${given_name}`,
        updateType: UpdateType.CONFIRM,
      }
    }
    return deliver2Confirm
  }

  const onCreateHandler = (state) => {
    setActionState(state)
    const id = row?.id;
    const sampleIds = row?.sampleIds
    const enableReport = id && sampleIds;
    const disableReport = id && !sampleIds;

    if (enableReport) {
      onCreateReport(id);
    } else if (disableReport) {
      setWarningReportModal(true);
      setWarningModal(false);
    } else {
      setWarningModal(true);
      setWarningReportModal(false);
    }

  }
  const onCreateReport = async (id) => {
    const data = {
      Id: GetResourceReportIdConfig(reportInfos),
      Module: ModuleIds.SampleDelivery,
      ItemAction: ReportAction.Create,
      ItemValue: `${id}`,
      reportParameters: {
        "DeliveryId": `${id}`,
        page: `${row.type}`
      }
    }
    onAddNewFile({ data: data, callback: afterCreateReport })
  }

  const afterCreateReport = (id) => {
    setRow({ ...row, fileId: id })
    onRefreshAfterAction();
  }

  const PrintConfig = async () => {
    const print = localStorage.getItem("printConfig")
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, row.id),
        "DeliveryId": `${row.id}`,
        "page": row.type
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, row.id),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }

    else {
      if (isEmpty(print)) {
        setOpen(true)
      }
      else {
        setModalPreview(true);
      }
    }
  }

  const onDeliveryListExportHandler = async () => {
    let params = getUrlParams()
    let paramPage = getUrlParamByKey("page")
    const fileData = await exportDeliveryList({ ...params, ...paramPage, type: 'U' })
    const blob = new Blob([fileData])
    saveAs(blob, "ExportDeliveryList.xlsx")
  }

  return (
    <React.Fragment>
      <ModalConfigPrinter open={open} onClose={(state) => {
        setDisableAction(false)
        setOpen(false)
      }}
        disableAction={disableAction}
        stateAction={actionState}
        resource={GetResourceReportConfig(reportInfos)}
        onPreviewClick={(state) => { onPreviewHandler(state) }}
        onPrintClick={(state) => { onPrintHandler(state) }}
        onExportClick={(state) => { onExportHandler(state) }}
      />
      {modalPreview &&
        <ModalPreview
          modal={modalPreview}
          toggle={() => { setModalPreview(false) }}
          fileId={row.fileId}
          reportId={GetResourceReportIdConfig(reportInfos)}
          resourceReport={ReportResource.DeliveryId}
          dataUrl={{
            ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, row.fileId),
            "DeliveryId": `${row.id}`,
            page: `${row.type}`
          }}
        />
      }
      {/* Body */}
      <TitleAndTable
        external
        table={() => (
          <CancelationRequestTable
            deliveries={deliveries}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadingDeliveries}
            updatedTime={updatedDeliveriesTime}
            onDeliveryListExport={onDeliveryListExportHandler}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            resourceReport={GetResourceReportConfig(reportInfos)}
            onCreateClick={(state) => { onCreateHandler(state) }}
            onPrintClick={(state) => { onPrintHandler(state) }}
            onReportSettingClick={(state) => { onReportSettingHandler(state) }}
            onPreviewClick={(state) => { onPreviewHandler(state) }}
            onExportClick={(state) => { onExportHandler(state) }}
            onDeleteClick={onDeleteToggleHandler}
            onEditClick={onEditHandler}
            onAddClick={addDeliveryClicks}
            onCloneClick={onCloneHandler}
            model={{}}
            disableCreateReport={row.state === DeliveryStateText.REPORTED}
          />
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        // title={t("Cancelation Request")}
        title={t("testRequestPage:Process management")}
        subtitle={t("Cancelation Request List")}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <WarningModal
        modal={warningReport}
        onToggle={() => setWarningReportModal(prev => !prev)}
        message={t("message:ReportWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Cancelation Request")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteDeliveryHandler}
      />
      <ConfirmModal2
        modal={confirmCreateReport}
        title={`${t("common:Create Report")} ${t("Sample Delivery")}`}
        message={`${t("message:ConfirmRecord", {
          entity: `${t("Sample Delivery")}`,
        })}`}
        onToggle={toggleConfirmCreateReport}
        onConfirm={confirmCreateReportHandler}
        isEdit
      />
      {modal &&
        <CancelationRequestModal
          formEl={formEl}
          modal={modal}
          isEdit={isEdit}
          onValidSubmit={handleValidSubmit}
          toggle={toggle}
          data={!isEdit ? (isClone ? row : {}) : delivery}
          myInfo={myInfo}
        />
      }
    </React.Fragment>
  )
}

CancelationRequest.propTypes = {
  deliveries: PropTypes.array,
  onGetDeliveryDetail: PropTypes.func,
  onGetDeliveries: PropTypes.func,
  onAddNewDelivery: PropTypes.func,
  onUpdateDelivery: PropTypes.func,
  onDeleteDeliveries: PropTypes.func,
  onResetDeliveriesSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingDeliveries: PropTypes.bool,
  updatedDeliveriesTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ sampleDelivery }) => {
  return {
    deliveries: sampleDelivery.cancelationRequests,
    delivery: sampleDelivery.delivery,
    paging: sampleDelivery.paging,
    loadingDeliveries: sampleDelivery.loadingDeliveries,
    updatedDeliveriesTime: sampleDelivery.updateDeliveriesTime,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDeliveries: payload => dispatch(getDeliveries(payload)),
  onAddNewDelivery: (delivery, history, resource) =>
    dispatch(addDelivery(delivery, history, resource)),
  onUpdateDelivery: delivery => dispatch(updateDelivery(delivery)),
  onDeleteDeliveries: deliveries => dispatch(deleteDeliveries(deliveries)),
  onGetDeliveryDetail: deliveryId => dispatch(getDeliveryDetail(deliveryId)),
  onResetDeliveriesSearchQuery: () => dispatch(resetDeliveriesSearchQuery()),
  onAddNewFile: (data, callback) =>
    dispatch(addNewFile(data, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(withTranslation(["deliveryPage", "message", "testRequestPage"])(CancelationRequest))
)
