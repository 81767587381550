import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Card, CardBody, Collapse } from "reactstrap"
import { deleteWorkflowCustom, getWorkflowCustom } from "store/actions"


const TreeViewBar_RulerManager = ({
  onChange,
  machines,
  t,
  getCustomWorkflowList
}) => {
  const [itemActive, setItemActive] = useState({
    Key: 2, Name: "Patient Range", parent: { ID_Group: '1', GroupName: t('common:Rule management') }
  })
  const [isChildOpenCollapse, setChildOpenCollapse] = useState(true)

  useEffect(() => {
    getCustomWorkflowList()
  }, [])
  const RulerTree = [
    {
      ID_Group: "1",
      GroupName: t("common:Rule management"),
      Children: [
        // {
        //   Key: 1,
        //   Name: "Normal Range",
        //   Result: 234,
        //   onClick: () => {
        //   },
        // },
        { Key: 2, Name: "Normal Range" },
        {
          Key: 3, Name: "Instrument Range",
          Children:
            machines.map((item, index) => ({ machineId: item.id, instrumentId: item.insID, Key: 3, childrenKey: 100 + index, Name: item.name }))
        },
      ],
    },
  ]

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar ">
        <CardBody>
          <div className="d-flex flex-column h-100">

            <div className="mb-3">
              <ul className="list-unstyled categories-list">
                <li>
                  <div className="custom-accordion custom-tree-view">
                    {RulerTree.map((value, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Collapse isOpen={true}>
                            <div className="card border-0 shadow-none ps-2 mb-0">
                              <ul className="list-unstyled categories-list">
                                {value.Children.map((child, index) => {
                                  return (
                                    <>
                                      <li key={index}>
                                        <a
                                          className={`${(child.Key == itemActive.Key && value.ID_Group == itemActive.parent?.ID_Group) ? "active" : ""} me-auto d-flex align-items-center treeViewReport`}
                                          style={{ fontSize: '13px' }}
                                          onClick={() => {
                                            let tmp = child
                                            tmp.parent = value
                                            setItemActive(tmp)
                                            if (tmp.Key !== 3) {
                                              onChange(tmp)
                                            }
                                          }}
                                        >
                                          {!child.Children && child.Name}
                                          {child.Children && <span onClick={(e) => {
                                            setChildOpenCollapse(!isChildOpenCollapse)
                                          }}>
                                            {child.Name}
                                          </span>}
                                          {child.Key === 3 && <i
                                            className={
                                              isChildOpenCollapse
                                                ? "mdi mdi-chevron-down accor-down-icon ms-auto"
                                                : "mdi mdi-chevron-up accor-down-icon ms-auto"
                                            }
                                          />}
                                        </a>
                                      </li>
                                      <li>
                                        {child.Children && <Collapse isOpen={isChildOpenCollapse}>
                                          <ul className="list-unstyled categories-list">
                                            {child.Children.map((child, index) => {
                                              return (
                                                <li key={index}>
                                                  <a
                                                    className={`${(child.childrenKey == itemActive.childrenKey && value.ID_Group == itemActive.parent?.ID_Group) ? "active" : ""} me-auto d-flex align-items-center treeViewReport`}
                                                    style={{ fontSize: '13px' }}
                                                    onClick={() => {
                                                      let tmp = child
                                                      tmp.parent = value
                                                      setItemActive({ ...tmp })
                                                      onChange(tmp)
                                                    }}
                                                  >
                                                    {child.Name}
                                                  </a>
                                                </li>
                                              )
                                            })}
                                          </ul>
                                        </Collapse>}
                                      </li>
                                    </>
                                  )
                                })}
                              </ul>
                            </div>
                          </Collapse>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const mapStateToProps = ({ RuleManagement }) => ({
  workflows: RuleManagement.workflows || [],
})

const mapDispatchToProps = dispatch => ({
  getCustomWorkflowList: payload => dispatch(getWorkflowCustom(payload)),
  onDeleteWorkflowCustom: (payload, callback) => dispatch(deleteWorkflowCustom(payload, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(TreeViewBar_RulerManager))

