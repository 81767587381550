import { Check, CustomBootstrapTable, CustomSID } from "components/Common"
import { useEffect, useState } from "react"

import { DeliveryStateText, ModuleIds, permissionType, SAMPLES_DELIVERY_TYPE } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.DeliveryManagement

const SampleTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  model,
  loading,
  qualityNameOptions,
  onChangeRemark,
  onChangeQuality,
  deliveryType,
  delivery,
  ...rest
}) => {

  const [checkDelivery, setCheckDelivery] = useState(
    delivery.state === DeliveryStateText.CONFIRMED ||
    delivery.state === DeliveryStateText.REPORTED ||
    delivery.state === DeliveryStateText.CLOSE
  )
  useEffect(() => {
    setCheckDelivery(
      delivery.state === DeliveryStateText.CONFIRMED ||
      delivery.state === DeliveryStateText.REPORTED ||
      delivery.state === DeliveryStateText.CLOSE
    )
  }, [delivery])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "deliveryId",
      text: "deliveryId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "sid",
      text: t("SID"),
      formatter: (cellContent, sample, index) => {
        return <CustomSID
          sid={`${sample.sid}${sample.subSID ? ` - ${sample.subSID}` : ''}`.trim()}
          textOnly={true}
        />
      }
    },
    {
      dataField: "patientName",
      text: t("Patient"),
      formatter: (cellContent, sample, index) => (
        <Link to={`/Patient/${sample.patientId}/view`} target="_blank">
          {cellContent}
        </Link>
      ),
    },
    {
      dataField: "sampleType",
      text: t("common:Code"),
      searchable: false,
    },
    {
      dataField: "sampleTypeName",
      text: t("Sample Name"),
    },
    {
      dataField: "qualityName",
      text: t("Quality"),
      searchable: false,
      formatter: (cellContent, sample, index) => (
        <span>{cellContent}</span>
        // <Editable
        //   disabled={false}
        //   editText={"Edit"}
        //   id={`${index}_quality`}
        //   initialValue={cellContent}
        //   isValueClickable
        //   mode="inline"
        //   onSubmit={(e) => { onChangeQuality(e, index) }}
        //   options={qualityNameOptions}
        //   placement="top"
        //   showText
        //   type="select"
        //   renderConfirmElement={confirmElement}
        //   renderCancelElement={cancelElement}
        // />
      ),
    },
    ...(SAMPLES_DELIVERY_TYPE.Correction === deliveryType ||
      SAMPLES_DELIVERY_TYPE.Cancelation === deliveryType
      ? [
        {
          dataField: "reasonName",
          text: t("Reason"),
          searchable: false,
          formatter: (cellContent, sample, index) => (
            <span>{cellContent}</span>
          ),
        },
      ]
      : [
        {
          dataField: "remark",
          text: t("common:Remark"),
          searchable: false,
          formatter: (cellContent, sample, index) => (
            <span>{cellContent}</span>
          ),
          // <Editable
          //   editText={"Edit"}
          //   id={`${index}_remark`}
          //   isValueClickable
          //   initialValue={cellContent}
          //   mode="inline"
          //   onSubmit={(e) => { onChangeRemark(e, index) }}
          //   onValidated={null}
          //   placement="top"
          //   renderConfirmElement={confirmElement}
          //   renderCancelElement={cancelElement}
          //   showText
          //   type="textfield"
          //   validate={value => {
          //     if (!value) {
          //       return "Required"
          //     }
          //   }}
          // />
        },
      ]),

    {
      dataField: "confirmedUserName",
      text: t("Confirmation Name"),
      searchable: false,
      formatter: (cellContent, sample, index) => (
        <Link to={`/User/${sample.confirmedUserId}/view`} target="_blank">
          {cellContent}
        </Link>
      ),
    },
    {
      dataField: "confirmedTime",
      text: t("Confirmation Time"),
      searchable: false,
      formatter: (cellContent, user, index) => convertDateFormat(cellContent),
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, sample, index) => (
        <div className="d-flex gap-3">
          {/* <Check permission={permissionType.U} resource={RESOURCE}>
            <Link
              to="#"
              // className="text-success"
              style={{
                color: checkDelivery ? "#a6a6fb" : "#556ee6",
              }}
              onClick={e => {
                checkDelivery ? () => { } : onEdit(e, sample)
              }}
            >
              <i className="mdi mdi-pencil font-size-18"
                data-tip
                data-for="edittooltip" />
              <ReactTooltip placement="top" id="edittooltip" effect="solid">
                Edit
              </ReactTooltip>
            </Link>
          </Check> */}

          {/* <Check permission={permissionType.D} resource={RESOURCE}>
            <Link
              to="#"
              className="text-danger"
              style={{
                color: checkDelivery ? "#ffabab" : "#dc3545",
              }}
              onClick={e => {
                checkDelivery ? () => { } : onDelete(e, sample)
              }}
            >
              <i className="mdi mdi-delete font-size-18"
                data-tip
                data-for="deletetooltip" />
              <ReactTooltip placement="top" id="deletetooltip" effect="solid">
                {t('Delete')}
              </ReactTooltip>
            </Link>
          </Check> */}
        </div>
      ),
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      data={data}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      isScrollable
      loading={loading}
      searchText={model.search}
      resource={RESOURCE}
      {...rest}
    />
  )
}

export default withTranslation(["deliveryPage", "common"])(SampleTable)
