import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField,
    CustomButton,
    CustomModal,
} from "components/Common"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { createHaemogramResult, getHaemogramResult, getHaemogramResultSuccess, updateHaemogramResult } from "store/actions"


const HaemogramCommentModal = ({
    modal,
    toggle,
    onUpdateHaemogramResult,
    onGetHaemogramResult,
    haemogramResult,
    onRefreshHaemogramResult,
    data,
    t
}) => {
    const formEl = useRef(null)
    const title = t("Haemogram Comment Detail")
    const [isDetect, setIsDetect] = useState(null)
    const [haemogramResultTest, setHaemogramResultTest] = useState(null)

    useEffect(() => {
        if (data && data?.id) {
            onGetHaemogramResult(data?.id, (data) => {
                if (data) {
                    setIsDetect(!!data)
                }
            })
        }
    }, [data])

    useEffect(() => {
        setHaemogramResultTest({ ...haemogramResult })
    }, [haemogramResult])

    const handleSubmit = (value) => {

        value.resultId = data?.id
        onUpdateHaemogramResult(value, () => {
            onToggle()
        })
    }

    const onToggle = () => {
        toggle()
        onRefreshHaemogramResult(null)
        setHaemogramResultTest(null)
        setIsDetect(null)
    }


    return (
        <CustomModal
            modal={modal}
            title={title}
            onToggle={onToggle}
            size="xl"
        >
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="haemogramForm"
                    onValidSubmit={(e, value) => {
                        handleSubmit(value)
                    }}
                >
                    <Row >
                        <Col sm={3} style={{ alignSelf: 'center' }}>
                            <Label className="form-check-label">{t('common:Comment')}</Label>
                        </Col>
                        <Col sm={9}>
                            <CustomAvField
                                type='textarea'
                                value={haemogramResultTest?.comment}
                                name='comment'
                                detected={isDetect}
                            // disabled={data.state === RESULT_STATE_Text.Confirmed}
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={3} style={{ alignSelf: 'center' }}>
                            <Label className="form-check-label">{t('common:Conclude')}</Label>
                        </Col>
                        <Col sm={9}>
                            <CustomAvField
                                type='textarea'
                                name='finalComment'
                                value={haemogramResultTest?.finalComment}
                                // required
                                detected={isDetect}
                            // {...isRequiredError("Microscopic", true, t)}
                            // disabled={data.state >= RESULT_STATE_Text.Confirmed}
                            />
                        </Col>
                    </Row>
                </AvForm>

            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={onToggle}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    // disabled={data.state >= RESULT_STATE_Text.Confirmed}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

HaemogramCommentModal.propTypes = {}

const mapStateToProps = ({ testResult }) => ({
    haemogramResult: testResult.haemogramResult,
    loading: testResult.loadingPatientResult,
})

const mapDispatchToProps = dispatch => ({
    onUpdateHaemogramResult: (payload, callback) => dispatch(updateHaemogramResult(payload, callback)),
    onGetHaemogramResult: (payload, callback) => dispatch(getHaemogramResult(payload, callback)),
    onCreateHaemogramResult: payload => dispatch(createHaemogramResult(payload)),
    onRefreshHaemogramResult: payload => dispatch(getHaemogramResultSuccess(payload)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(HaemogramCommentModal))
