import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import Select, { components } from "react-select"
import {
  getCodesByParameterId
} from "helpers/app-backend"

import { useDetectedChanges } from "helpers/hooks"
import { convertStringForSearch, getI18nextLng, isEmptyArray } from "helpers/utilities"
import { parameterCode } from "constant/utility"
import { debounce, isEmpty } from "lodash"
import { Label } from "reactstrap"
import { RollbackButton } from "."

import NumberFormat from "react-number-format"

const CustomSelectInputField = ({
  value,
  name,
  required,
  errorMessage,
  code,
  group,
  onChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  searchQuery,
  showDefaultAll,
  isDebounce,
  iconSearch,
  resetTimeout,
  isClearable = true,
  autoFocus = false,
  isInsurance = false,
  t
}) => {
  const [optionSelect, setOptionSelect] = useState([])
  const [inputValue, setInputValue] = useState(value || '')
  const [showOptions, setShowOptions] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState([])

  const handleInputChange = (event) => {
    const value = event.target.value
    setInputValue(value)
    const filterSelect = optionSelect.filter((option) =>
      convertStringForSearch(option || '').includes(convertStringForSearch(value || ''))
    )
    setFilteredOptions(filterSelect)
    setShowOptions(true)
  }

  const handleOptionClick = (option) => {
    setInputValue(option)
    setShowOptions(false)
  }

  const handleInputFocus = () => {
    setShowOptions(true)
  }

  const handleInputBlur = () => {
    setTimeout(() => setShowOptions(false), 100)
  }
  let lang = getI18nextLng()

  // COMMON CODES
  const fetchCodes = async (code, group, searchQuery) => {
    const query = { lang, group, ...searchQuery }
    const res = await getCodesByParameterId(code, query)


    res?.map(_item => {
      _item.value = _item.code
      _item.label = isInsurance ? _item.code : _item.message
      return _item
    })
    let isExist = res.find(x => x.code == value)
    if (!isExist) {
      res.push({
        value: value,
        label: value,
      })
    }


    return res
  }

  useEffect(() => {
    if (code)
      fetchOptions(code, group, searchQuery)
  }, [code, group, searchQuery])


  const fetchOptions = useCallback(async (code, group, searchQuery) => {
    let res = []
    res = await fetchCodes(code, group, searchQuery)
    const labels = res.map(item => item.label)
    setOptionSelect(labels)
    setFilteredOptions(labels)
  }, [])

  return (
    <>
      <div className='select-physican' style={{ position: 'relative' }}>
        <AvField
          name={name}
          type="text"
          required
          disabled={readOnly}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          style={{ width: '100%', padding: '8px', boxSizing: 'border-box', margin: 0 }}
        />
        {showOptions && filteredOptions.length > 0 && (
          <ul
            style={{
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              position: 'absolute',
              width: '100%',
              maxHeight: '150px',
              overflowY: 'auto',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              zIndex: 1,
            }}
          >
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onMouseDown={() => handleOptionClick(option)}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #gray',
                  backgroundColor: option === inputValue && '#0087ff',
                  color: option === inputValue ? 'white' : 'black'
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

CustomSelectInputField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  group: PropTypes.string,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  searchQuery: PropTypes.object,
  showDefaultAll: PropTypes.bool,
  isClearable: PropTypes.bool,
}

CustomSelectInputField.defaultProps = {
  name: "",
  onChange: () => { },
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  searchQuery: {},
}

export default (withRouter(withTranslation(["common"])(CustomSelectInputField)))
