import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import classNames from "classnames"
import { Col, Row } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// Import table style
import "./datatables.scss"

import { Check, CustomButton, FilterButton, Now } from "components/Common"
import { permissionType } from "constant"
import { trimAndRemoveMultipleSpaces } from "helpers/utilities"
import ExportButton from "./ExportButton"
import CustomSearchBar from "./SearchBar"

const CustomBootstrapTable = ({
  data,
  columns,
  search,
  onSelect,
  onSort,
  onSelectAll,
  onSearch,
  onRefresh,
  onReset,
  filterForm,
  isScrollable,
  loading,
  isEnableLoadInfo,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  t,
  searchText: searchTextProps,
  updatedTime,
  resource,
  isEnableExport,
  keyField = "id",
  isEnableRefresh,
  customButtonTable,
  showSelectRow,
  onRowClick,
  selectedRows,
  expandRow,
  striped = false,
  isHover = true,
  rowClasses,
  headerClasses,
  defaultSortValue,
  isImportExportButton = false,
  importExportForm,
  onScroll,
  TableTitle,
  customButtonRight,
  isSelected,
  style
}) => {
  const { page, dataSize, size: sizePerPageProp, totalPages } = paging
  const [sizePerPage, setSizePerPage] = useState(sizePerPageProp)
  const [searchText, setSearchText] = useState("")
  const [selected, setSelected] = useState([])
  const [defaultSorted, setDefaultSorted] = useState({})

  const handleOnSelect = (row, isSelected) => {
    let id
    if (isSelected) {
      id = row.id
      setSelected(prev => [...prev, row.id])
    } else {
      setSelected(prev => [...prev.filter(x => x !== row.id)])
    }
    onSelect(row, isSelected)
  }

  useEffect(() => {
    if (isSelected) {
      resetCheckbox()
    }
  }, [data])

  const handleOnSelectAll = (isSelected, rows) => {
    const ids = rows.map(r => r.id)
    let results = [...rows]
    if (isSelected) {
      setSelected(ids)
    } else {
      results = []
      setSelected([])
    }

    onSelectAll(results, isSelected)
  }

  const resetCheckbox = () => {
    setSelected([])
    onSelectAll([])
  }

  const onPageChangeHandler = page => {
    resetCheckbox()
    onPageChange(page)
  }

  const pageOptions = {
    page,
    // sizePerPage: isScrollable ? data.length : 1, //sizePerPageProp
    sizePerPage: isScrollable ? data.length : sizePerPageProp,
    totalSize: dataSize, // replace later with size(customers),
    custom: true,
    onPageChange: onPageChangeHandler,
    // sizePerPageList: [5, 10, 25, 50, 100],
    showTotal: true,
    hideSizePerPage: false,
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected: selectedRows ? selectedRows : selected, // should be a row keys array
  }

  const onResetHandler = () => {
    resetCheckbox()
    setDefaultSorted({ dataField: "id", order: "asc" })
    onReset()
  }

  const onRefreshHandler = () => {
    resetCheckbox()
    onRefresh()
  }

  const onSearchHandler = searchText => {
    if (onSearch) {
      onSearch(searchText)
      resetCheckbox()
    }
  }

  const onSearchChangeHandler = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  const onSubmitSearchHandler = e => {
    e.preventDefault()
    onSearchHandler(trimAndRemoveMultipleSpaces(searchText))
  }

  const onClearSearchHandler = () => {
    setSearchText("")
    onSearchHandler("")
    onSearch("")
  }

  const onSizePerPageChangeHandler = size => {
    setSizePerPage(size)
    onSizePerPageChange(size)
  }

  const onSubmit = (e, values) => {
    if (onSubmitFilter) {
      onSubmitFilter(values)
      resetCheckbox()
    }
  }

  const onTableChange = (e, { sortField, sortOrder }) => {
    if (e === "sort") {
      setDefaultSorted({ dataField: sortField, order: sortOrder })
      onSort(sortField, sortOrder)
    }
  }

  const sortOptions = {
    // sortCaret: (order, column) => {
    //   if (!order) return <span>&nbsp;&nbsp;Desc/Asc</span>
    //   else if (order === "asc")
    //     return (
    //       <span>
    //         &nbsp;&nbsp;Desc/<font color="red">Asc</font>
    //       </span>
    //     )
    //   else if (order === "desc")
    //     return (
    //       <span>
    //         &nbsp;&nbsp;<font color="red">Desc</font>/Asc
    //       </span>
    //     )
    //   return null
    // },
  }

  useEffect(() => {
    setSearchText(searchTextProps)
  }, [searchTextProps])

  const NoDataIndication = () => (
    <div className="text-center">
      {loading ? t("Loading...") : t("No records found")}
    </div>
  )

  useEffect(() => {
    const table = document.getElementById("resizeMeAsync")
    // Query all headers
    const cols = table.querySelectorAll("th")
      // Loop over them
      ;[].forEach.call(cols, function (col, index) {
        const resizer = document.createElement("div")
        resizer.classList.add("resizer")
        col.appendChild(resizer)
        createResizableColumn(col, resizer)

        const resizerLeft = document.createElement("div")
        resizerLeft.classList.add("resizerLeft")
        col.appendChild(resizerLeft)
        if (index > 0) {
          createResizableColumn(cols[index - 1], resizerLeft)
        }
      })
    $(".resizer").on("click", function (e) {
      e.stopPropagation()
    })
    setDefaultSorted(defaultSortValue)
  }, [])

  const createResizableColumn = function (col, resizer) {
    // Track the current position of mouse
    let x = 0
    let w = 0

    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX

      // Calculate the current width of column
      const styles = window.getComputedStyle(col)
      w = parseInt(styles.width, 10)

      // Attach listeners for document's events
      document.addEventListener("mousemove", mouseMoveHandler)
      document.addEventListener("mouseup", mouseUpHandler)
    }

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x

      // Update the width of column
      col.style.width = `${w + dx}px`
    }

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener("mousemove", mouseMoveHandler)
      document.removeEventListener("mouseup", mouseUpHandler)
    }

    resizer.addEventListener("mousedown", mouseDownHandler)
  }
  const tableRowEvents = {
    onClick: (e, row, index) => onRowClick(e, row, index),
  }
  const handleScroll = e => {
    handleEndScroll(e);
  };
  const handleEndScroll = useMemo(
    (e) =>
      _.debounce((e) => onScroll(e), 10),
    []
  );

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    >
      {({ paginationProps, paginationTableProps }) => {
        const { sizePerPage, page } = paginationProps

        let recordsCountFrom = (page - 1) * sizePerPage + 1
        let lastRecordsCount = recordsCountFrom + sizePerPage - 1
        let recordsCountTo =
          lastRecordsCount > dataSize ? dataSize : lastRecordsCount

        return (
          <React.Fragment>
            {isEnableLoadInfo && (
              <div className="mb-1 px-2">
                <div className="d-flex flex-wrap flex-row">
                  {isEnableLoadInfo && (
                    <div className="align-self-center">
                      {TableTitle &&
                        <div className="table-title">{TableTitle}</div>
                      }
                      <span className="font-size-12">
                        {" "}
                        {dataSize >= 0
                          ? t("table:totalRow", {
                            records: recordsCountTo,
                            size: dataSize,
                          })
                          : t("table:totalRow", {
                            records: 0,
                            size: 0,
                          })}{" "}
                        &bull; {t("Updated")} <Now date={updatedTime} />
                      </span>
                    </div>
                  )}

                  <div className="ms-auto">
                    {search && (
                      <CustomSearchBar
                        onSearch={onSearchChangeHandler}
                        searchText={searchText}
                        placeholder={t("Search")}
                        onClear={onClearSearchHandler}
                        onSubmit={onSubmitSearchHandler}
                      />
                    )}
                  </div>
                  <div className="ms-0 align-self-center">
                    <div className="toolbar button-items text-end">
                      {customButtonTable && customButtonTable()}
                      <Check resource={resource} permission={permissionType.R}>
                        {isEnableRefresh && (
                          <CustomButton
                            color="secondary"
                            outline
                            onClick={onRefreshHandler}
                          >
                            <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
                            {/* <i className="fas fa-sync-alt"></i> */}
                          </CustomButton>
                        )}
                        {/* FilterButton */}
                        {filterForm && (
                          <FilterButton
                            onReset={onResetHandler}
                            onSubmit={onSubmit}
                          >
                            {filterForm()}
                            {/* <FilterForm model={model} /> */}
                          </FilterButton>
                        )}
                      </Check>
                      <Check resource={resource} permission={permissionType.E}>
                        {isEnableExport &&
                          <>
                            {isImportExportButton == true ?
                              importExportForm()
                              :
                              <ExportButton data={data} />
                            }
                          </>
                        }
                      </Check>
                      {customButtonRight &&
                        <Check resource={resource} permission={permissionType.U}>
                          <>
                            {customButtonRight()}
                          </>
                        </Check>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={classNames(
                { "table-scrollable": isScrollable },
                "table-responsive",
                "px-0"
              )}
              style={{ minHeight: '150px', ...style }}
              onScroll={e => {
                onScroll && handleScroll(e)
              }}
            >
              <BootstrapTable
                remote
                id="resizeMeAsync"
                keyField={keyField}
                responsive
                bordered={false}
                striped={striped}
                data={data}
                columns={columns}
                onTableChange={onTableChange}
                sort={defaultSorted}
                // sort={sortOptions}
                selectRow={showSelectRow ? selectRow : undefined}
                // selectRow={selectRow}
                expandRow={expandRow}
                headerClasses={headerClasses || "table-light"}
                rowClasses={rowClasses}
                rowEvents={tableRowEvents}
                noDataIndication={() => <NoDataIndication />}
                classes={classNames(
                  { "header-fixed": isScrollable },
                  "table",
                  isScrollable && 'm-0',
                  "align-middle",
                  // "table-nowrap",
                  { "table-hover": isHover }
                )}
                // headerWrapperClasses={"thead-light"}
                // {...toolkitProps.baseProps}
                {...paginationTableProps}
              />
            </div>

            {!isScrollable && totalPages >= 1 && (
              <Row className="align-items-md-center mt-30 mx-1">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone
                      {...paginationProps}
                      onSizePerPageChange={onSizePerPageChangeHandler}
                    />
                  </div>
                  {loading && (
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">{t("Loading...")}</span>
                    </div>
                  )}
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )
      }}
    </PaginationProvider>
  )
}

CustomBootstrapTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  search: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  filterForm: PropTypes.func,
  isScrollable: PropTypes.bool,
  loading: PropTypes.bool,
  isEnableLoadInfo: PropTypes.bool,
  paging: PropTypes.object.isRequired,
  onSizePerPageChange: PropTypes.func.isRequired,
  onSubmitFilter: PropTypes.func,
  t: PropTypes.any,
  searchText: PropTypes.string.isRequired,
  updatedTime: PropTypes.any.isRequired,
  resource: PropTypes.string,
  onRowClick: PropTypes.func,
  rowClasses: PropTypes.any,
  defaultSortValue: PropTypes.object,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
}

CustomBootstrapTable.defaultProps = {
  data: [],
  columns: [],
  onSelect: () => { },
  onSelectAll: () => { },
  onSearch: () => { },
  onSort: () => { },
  onRefresh: () => { },
  onReset: () => { },
  loading: false,
  isEnableLoadInfo: true,
  paging: { dataSize: 0, page: 1, sizePerPage: 10 },
  searchText: "",
  updatedTime: new Date(),
  isEnableExport: true,
  isEnableRefresh: true,
  showSelectRow: true,
  onRowClick: () => { },
  rowClasses: "",
  headerClasses: ""
}

export default withTranslation(["common", "table"])(CustomBootstrapTable)
